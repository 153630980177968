import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const features = [
  {
    title: "Key Features for SaaS Businesses",
    features: [
      <p>
        <span>Recurring Commission Tracking:</span> Accurately track and manage
        commissions for your subscription-based products.
      </p>,
      <p>
        <span>Free Trial Management:</span> Set up and track free trial
        conversions driven by your affiliate network.
      </p>,
      <p>
        <span>Coupon Code Management:</span> Generate unique coupon codes for
        affiliates to promote your SaaS offerings.
      </p>,
    ],
  },
  {
    title: "Scale with Confidence",
    features: [
      <p>
        <span>Affiliate Recruitment: </span>Find high-potential affiliates
        perfectly aligned with your target audience.
      </p>,
      <p>
        <span>Performance Tracking:</span> Monitor individual and overall
        affiliate activity for data-driven decision-making.
      </p>,
      <p>
        <span>Payouts:</span> Manage your payouts on Paypal and ensure timely
        transactions to keep your affiliates happy.
      </p>,
    ],
  },
];

const FeatureCol2 = () => {
  return (
    <Box maxW={{ base: "none", lg: "33%" }}>
      <Box borderLeft="1px solid #DFE7EA">
        <Text
          fontWeight="600"
          lineHeight="24px"
          color="#131316"
          fontSize="1rem"
          pl="12px"
          borderLeft="2px solid #635BFF"
        >
          {features[0].title}
        </Text>

        <Box ml="12px" pl="21px" mt="22px" borderLeft="1px solid #DFE7EA">
          {features[0].features
            .filter((_, index) => index < 2)
            .map((ft, index) => (
              <Box
                key={index}
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="24px"
                color="#5E5F6E"
                position="relative"
                pb={"24px"}
              >
                <Box
                  bgColor="white"
                  rounded="full"
                  h="16px"
                  w="16px"
                  position="absolute"
                  left="-28px"
                  top="0px"
                  border="0.5px solid rgba(18, 55, 105, 0.08)"
                  shadow="sm"
                />

                {ft}
              </Box>
            ))}
        </Box>

        <Box
          fontSize="0.875rem"
          fontWeight="400"
          lineHeight="24px"
          color="#5E5F6E"
          position="relative"
          pb={"24px"}
          ml="12px"
          pl="21px"
        >
          <Box
            bgColor="white"
            rounded="full"
            h="16px"
            w="16px"
            position="absolute"
            left="-6px"
            top="0px"
            border="0.5px solid rgba(18, 55, 105, 0.08)"
            shadow="sm"
          />

          {features[0].features[2]}
        </Box>
      </Box>

      <Box borderLeft="1px solid #DFE7EA">
        <Text
          fontWeight="600"
          lineHeight="24px"
          color="#131316"
          fontSize="1rem"
          pl="12px"
          borderLeft="2px solid #635BFF"
        >
          {features[1].title}
        </Text>

        <Box ml="12px" pl="21px" mt="22px" borderLeft="1px solid #DFE7EA">
          {features[1].features
            .filter((_, index) => index < 2)
            .map((ft, index) => (
              <Box
                key={index}
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="24px"
                color="#5E5F6E"
                position="relative"
                pb={"24px"}
              >
                <Box
                  bgColor="white"
                  rounded="full"
                  h="16px"
                  w="16px"
                  position="absolute"
                  left="-28px"
                  top="0px"
                  border="0.5px solid rgba(18, 55, 105, 0.08)"
                  shadow="sm"
                />

                {ft}
              </Box>
            ))}
        </Box>
      </Box>

      <Flex>
        <Box borderLeft="2px solid #635BFF" h="20px" mr="12px" />

        <Box
          ml="21px"
          fontSize="0.875rem"
          fontWeight="400"
          lineHeight="24px"
          color="#5E5F6E"
          position="relative"
        >
          <Box
            bgColor="white"
            rounded="full"
            h="16px"
            w="16px"
            position="absolute"
            border="0.5px solid rgba(18, 55, 105, 0.08)"
            left="-28px"
            top="0px"
            shadow="sm"
          />

          {features[1].features[2]}
        </Box>
      </Flex>
    </Box>
  );
};

export default FeatureCol2;
