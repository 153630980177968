import {
  Flex,
  Button,
  Link,
  useColorModeValue,
  useDisclosure,
  Image,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";

import { GoTriangleRight } from "react-icons/go";

import { useContext, useState } from "react";
import { NavContext } from "../../context/NavContext";
import { RiMenuUnfoldLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { AiOutlineFileSearch } from "react-icons/ai";

import { Link as RouterLink } from "react-router-dom";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

const Navbar2 = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { isAdvertiser, setisAdvertiser } = useContext(NavContext);
  const [menu, setMenu] = useState(false);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");

  const NAV_ITEMS = [
    // {
    //   label: "For SAAS",
    //   href: "/appsintegration",

    //   icon: AiOutlineFileSearch,
    // },
    {
      label: "For SAAS",
      href: "/forsaas",

      icon: AiOutlineFileSearch,
    },
    {
      label: "For E-commerce",
      href: "/ecommerce",
      icon: AiOutlineFileSearch,
    },

    {
      label: "Integrations",
      href: "/appsintegration",

      icon: AiOutlineFileSearch,
    },
    // {
    //   label: "For Affiliates",
    //   href: "/appsintegration",

    //   icon: AiOutlineFileSearch,
    // },
    // {
    //   label: "Resources",
    //   href: "/pricing",

    //   icon: IoPricetagsOutline,
    // },
    // {
    //   label: "Marketplace",
    //   href: "/pricing",
    //   options: [{ test: "test" }],
    //   icon: IoPricetagsOutline,
    // },
    {
      label: "Pricing",
      href: "/pricing",

      icon: IoPricetagsOutline,
    },
  ];

  return (
    <Flex justify={"center"} position={"relative"} zIndex={20} bg="#F7F9FC">
      <Flex
        minH={"70px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        w={{ base: "100%", lg: "90%" }}
        bgColor={"#F7F9FC"}
        flexDir={"column"}
        zIndex={20}
      >
        <Flex
          w={"100%"}
          bgColor={"#fff"}
          py={2}
          px={5}
          //   borderWidth={1}
          borderRadius={10}
          //   borderColor={"#c4c4c4"}
          shadow={"base"}
          justify={"space-between"}
          align={"center"}
        >
          <Flex gap={10} flexDir={"row"}>
            <RouterLink to="/">
              <Image
                src="/assets/png/METRICKS-main-logo 5.png"
                // w="100px"
                h={["30px", "30px"]}
              />
            </RouterLink>
            {isDesktop && (
              <Flex gap={3} justify={"space-between"} align={"center"}>
                {NAV_ITEMS.map((navItem) => (
                  <Flex key={navItem.label}>
                    <Link
                      p={2}
                      href={navItem.href}
                      fontSize={"sm"}
                      fontWeight={400}
                      color={"#000"}
                      _hover={{
                        textDecoration: "none",
                        color: "#8c3cdc",
                      }}
                    >
                      <Flex align={"center"}>
                        {navItem.label}
                        {/* {navItem.options && <IoIosArrowDown />} */}
                      </Flex>
                    </Link>
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
          <Flex gap={10} flexDir={"row"}>
            {isDesktop ? (
              <></>
            ) : // <RouterLink to="https://app.metricks.io/login">
            //   <Button
            //     rightIcon={<GoTriangleRight />}
            //     bgColor={"#333337"}
            //     color={"#c4c4c4"}
            //     borderRadius={10}
            //     fontWeight={"500"}
            //     _hover={{
            //       color: "#fff",
            //       bgColor: "#c4c4c4",
            //     }}
            //     bgGradient="linear(#48484C 40%, #232328 60%)"
            //   >
            //     Sign in
            //   </Button>
            // </RouterLink>
            menu ? (
              <CloseIcon onClick={() => setMenu(false)} />
            ) : (
              <IconButton
                aria-label="Search database"
                icon={<HamburgerIcon />}
                onClick={() => setMenu(true)}
              />
            )}
          </Flex>
        </Flex>
        {menu && (
          <Flex
            bgColor={"white"}
            zIndex={20}
            w={"100%"}
            p={3}
            flexDir={"column"}
            gap={5}
          >
            <Flex
              gap={2}
              flexDir={"column"}
              borderWidth={1}
              w={"100%"}
              borderRadius={10}
              p={3}
            >
              {NAV_ITEMS.map((navItem) => (
                <Flex key={navItem.label}>
                  <Link
                    p={2}
                    href={navItem.href}
                    fontSize={"sm"}
                    fontWeight={400}
                    color={"#000"}
                    _hover={{
                      textDecoration: "none",
                      color: "#8c3cdc",
                    }}
                  >
                    <Flex align={"center"}>
                      {navItem.label}
                      {/* {navItem.options && <IoIosArrowDown />} */}
                    </Flex>
                  </Link>
                </Flex>
              ))}
            </Flex>
            <Flex w={"100%"}>
              {/* <RouterLink to="https://app.metricks.io/login">
                <Button
                  rightIcon={<GoTriangleRight />}
                  bgColor={"#333337"}
                  color={"#c4c4c4"}
                  borderRadius={10}
                  fontWeight={"500"}
                  _hover={{
                    color: "#fff",
                    bgColor: "#c4c4c4",
                  }}
                  bgGradient="linear(#48484C 40%, #232328 60%)"
                  w={"100%"}
                >
                  Sign in
                </Button>
              </RouterLink> */}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Navbar2;
