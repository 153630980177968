import { Button, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { GiBullseye } from "react-icons/gi";
import { GoTriangleRight } from "react-icons/go";
import { LiaBullseyeSolid } from "react-icons/lia";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const Integrations = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  return (
    <Flex justify={"center"} bgColor={"#fff"}>
      <Flex
        align="center"
        justify={"center"}
        w={{ base: "90%", lg: "80%" }}
        py={20}
        flexDir={"column"}
        gap={5}
        overflow={"hidden"}
      >
        <Flex
          align={"center"}
          gap={3}
          px={3}
          py={2}
          bgColor={"#fff"}
          borderWidth={1}
          borderColor={"#c4c4c4"}
          shadow={"base"}
          borderRadius={50}
        >
          <LiaBullseyeSolid size={20} />
          <Text>Integrations</Text>
        </Flex>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          transition={{ duration: 0.8 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          style={{ width: isDesktop ? "60%" : "100%" }}
        >
          <Text fontWeight={"500"} fontSize={35} textAlign={"center"}>
            Seamless Integrations you’ll love
          </Text>
          <Text
            fontWeight={"300"}
            fontSize={13}
            textAlign={"center"}
            w={["100%", "100%"]}
          >
            Metricks understands that your affiliate marketing program doesn't
            exist in a silo. To maximize efficiency and unlock new
            opportunities, Metricks seamlessly integrates with a wide range of
            popular tools you already use
          </Text>
        </motion.div>

        <Flex
          w={"100%"}
          gap={1}
          mt={10}
          flexDir={!isDesktop ? "column" : "row"}
        >
          <Flex flex={1} h={"450px"}>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 0.3, duration: 2 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#F7F7F8"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                px={10}
                _hover={{
                  shadow: "2xl",
                  bgColor: "#fff",
                }}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5379e28e-774e-4f55-a7dd-ef814c595a04.svg"
                  w="80%"
                />
                <Flex w={"100%"}>
                  <Text fontWeight={"500"}>Marketing Automation Tools</Text>
                </Flex>
                <Text fontWeight={"200"} fontSize={14} textAlign={"justify"}>
                  Integrate Metricks with your marketing automation platform
                  (e.g., Mailchimp, HubSpot) to create targeted email campaigns
                  for your affiliates, fostering stronger relationships and
                  driving conversions
                </Text>
                <Flex w={"100%"} mb={10}>
                  <Link to={"/appsintegration"}>
                    <Button
                      rightIcon={<GoTriangleRight />}
                      color={"#000"}
                      borderRadius={10}
                      fontWeight={"400"}
                      shadow={"base"}
                      _hover={{
                        color: "#c4c4c4",
                        //   bgColor: "#c4c4c4",
                      }}
                      bgColor={"#fff"}
                    >
                      View all
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </motion.div>
          </Flex>

          <Flex flex={1} flexDir={"column"} gap={1}>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 0.3, duration: 0.8 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#F7F7F8"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                px={10}
                position={"relative"}
                overflow={"hidden"}
                _hover={{
                  shadow: "2xl",
                  bgColor: "#fff",
                }}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/236069d8-4090-4b3b-9b1f-d34b7b066568.svg"
                  w="80%"
                  position={"absolute"}
                  top={isDesktop ? -7 : -20}
                />
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/4888ce93-3e79-49e3-9511-8cc20086da0a.svg"
                  w="60%"
                  position={"absolute"}
                  top={12}
                />
                <Flex w={"100%"} mt={isDesktop ? "120px" : "250px"}>
                  <Text fontWeight={"500"}>E-commerce Platforms</Text>
                </Flex>
                <Text fontWeight={"200"} fontSize={14} textAlign={"justify"}>
                  Connect effortlessly with leading e-commerce platforms like
                  Shopify, WooCommerce, and BigCommerce to streamline product
                  data and sales tracking.
                </Text>
                <Flex w={"100%"} mb={10}>
                  <Link to={"/appsintegration"}>
                    <Button
                      rightIcon={<GoTriangleRight />}
                      color={"#000"}
                      borderRadius={10}
                      fontWeight={"400"}
                      shadow={"base"}
                      _hover={{
                        color: "#c4c4c4",
                        //   bgColor: "#c4c4c4",
                      }}
                      bgColor={"#fff"}
                    >
                      View all
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 0.5, duration: 0.8 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#F7F7F8"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                px={10}
                position={"relative"}
                overflow={"hidden"}
                _hover={{
                  shadow: "2xl",
                  bgColor: "#fff",
                }}
              >
                {/* <Image
                  src="/assets/svg/ecomerce.svg"
                  w="80%"
                  position={"absolute"}
                  top={-7}
                /> */}
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a5ad9eae-eefb-4b25-887d-79e9f0774eed.svg"
                  w="60px"
                  position={"absolute"}
                  top={12}
                />
                <Flex w={"100%"} mt={"120px"}>
                  <Text fontWeight={"500"}>Analytics Platforms</Text>
                </Flex>
                <Text fontWeight={"200"} fontSize={14} textAlign={"justify"}>
                  Connect Metricks with analytics tools like Google Analytics to
                  gain deeper insights into your affiliate program's
                  performance. Analyze website traffic, customer behavior, and
                  campaign effectiveness for a holistic view of your marketing
                  efforts.
                </Text>
                <Flex w={"100%"} mb={10}>
                  <Link to={"/appsintegration"}>
                    <Button
                      rightIcon={<GoTriangleRight />}
                      color={"#000"}
                      borderRadius={10}
                      fontWeight={"400"}
                      shadow={"base"}
                      _hover={{
                        color: "#c4c4c4",
                        //   bgColor: "#c4c4c4",
                      }}
                      bgColor={"#fff"}
                    >
                      View all
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </motion.div>
          </Flex>

          <Flex flex={1} h={"450px"}>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 0.75, duration: 0.8 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#F7F7F8"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                px={10}
                _hover={{
                  shadow: "2xl",
                  bgColor: "#fff",
                }}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/20f682b5-17be-4f4d-8f13-2850824a7f8e.svg"
                  w="80%"
                />
                <Flex w={"100%"}>
                  <Text fontWeight={"500"}>Payment Gateways</Text>
                </Flex>
                <Text fontWeight={"200"} fontSize={14} textAlign={"justify"}>
                  Simplify payouts to your affiliates with popular payment
                  processors like Stripe and PayPal. Ensure timely and secure
                  transactions to keep your affiliates happy and engaged.
                </Text>
                <Flex w={"100%"} mb={10}>
                  <Link to={"/appsintegration"}>
                    <Button
                      rightIcon={<GoTriangleRight />}
                      color={"#000"}
                      borderRadius={10}
                      fontWeight={"400"}
                      shadow={"base"}
                      _hover={{
                        color: "#c4c4c4",
                        //   bgColor: "#c4c4c4",
                      }}
                      bgColor={"#fff"}
                    >
                      View all
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </motion.div>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Integrations;
