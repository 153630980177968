import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ChevronRightIcon, PhoneIcon, Search2Icon } from "@chakra-ui/icons";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { Integrations, IntegrationsCategories } from "../helper/DummyData";
import ReactPaginate from "react-paginate";
import "../App.css";
import { useLocation } from "react-router-dom";
const AppsIntegration = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const [selectedButton, setSelectedButton] = useState("All Products");
  const [filteredArray, setFilteredArray]: any = useState(Integrations);
  const [category, setCategory]: any = useState("");
  const [searchResultsalign, setSearchResultsalign]: any = useState(false);
  const [forceNumber, setForceNumber] = useState(0);
  const handleButtonClick = (buttonId: string) => {
    setSelectedButton(buttonId);
  };
  const filterByCategory = (category: string) => {
    // console.log(category);
    setCategory(category);
    setItemOffset(0);
    setForceNumber(0);
    // setpageCount(0);
    if (category === "All Products") {
      setFilteredArray(Integrations);
    } else {
      const filteredArray = Integrations.filter(
        (integration) => integration.category === category
      );
      // console.log(filteredArray);
      setFilteredArray(filteredArray);
    }
  };

  const filterByTitle = (searchText: string) => {
    setItemOffset(0);
    // setForceNumber(0);
    // setpageCount(0);
    if (searchText === "") {
      if (category === "") {
        setFilteredArray(Integrations);
        setSearchResultsalign(false);
      } else {
        filterByCategory(category);
      }
    } else {
      console.log(filteredArray);
      const filteredArray2 = Integrations.filter((integration: any) =>
        integration.title.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredArray(filteredArray2);
      setSearchResultsalign(true);
    }
  };

  const itemsPerPage = 9;

  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setcurrentItems]: any = useState([]);
  const [pageCount, setpageCount] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setcurrentItems(filteredArray.slice(itemOffset, endOffset));
    setpageCount(Math.ceil(filteredArray.length / itemsPerPage));
    setForceNumber(0);
  }, [itemOffset, filteredArray, category, pageCount]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredArray.length;

    setItemOffset(newOffset);
  };

  return (
    <Flex direction={"column"} align={"center"}>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
        width={"100%"}
      >
        <Flex
          align="center"
          justify={"center"}
          minH="25vh"
          direction={"column"}
        >
          <Heading
            as="h1"
            size="xl"
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center", "left", "left"]}
          >
            Apps & Integrations
          </Heading>
        </Flex>
      </Flex>
      <Flex
        position={"relative"}
        justify={"center"}
        backgroundColor={"white"}
        w={"100%"}
      >
        {/* //search */}
        <Flex
          backgroundColor={"white"}
          width={"80%"}
          align={"center"}
          position={"absolute"}
          top={-5}
          padding={2}
          justify={"center"}
          shadow={"xl"}
        >
          <InputGroup width={"100%"} borderColor={"white"}>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              type="tel"
              placeholder="Search Integration"
              onChange={(e) => filterByTitle(e.target.value)}
            />
          </InputGroup>
        </Flex>
        {/* {content} */}
        <Flex flex={1} p={[5, 20]} direction={["column", "row"]}>
          <Flex flex={1} direction={"column"} p={[2, 10]} pt={[10, 0]}>
            <Heading size={"md"} mb={5}>
              All Categories
            </Heading>
            {!isDesktop && (
              <Select
                placeholder="Direct Integrations"
                onChange={(e) => {
                  // console.log(e.target.value);
                  handleButtonClick(e.target.value);
                  filterByCategory(e.target.value);
                }}
              >
                {IntegrationsCategories.map((item, i) => (
                  <option value={item}>{item}</option>
                ))}
              </Select>
            )}
            {isDesktop &&
              IntegrationsCategories.map((item, i) => (
                <Button
                  bg={selectedButton === item ? "#f1e7fb" : "white"}
                  color={selectedButton === item ? "#7139d4" : "black"}
                  fontWeight={"medium"}
                  _hover={{ bg: "#f1e7fb" }}
                  onClick={() => {
                    handleButtonClick(item);
                    filterByCategory(item);
                  }}
                  justifyContent={"flex-start"}
                  borderRadius={50}
                  mt={2}
                  key={i}
                >
                  {item}
                </Button>
              ))}
          </Flex>

          <Flex
            flex={3}
            py={10}
            flexWrap={"wrap"}
            justify={
              searchResultsalign
                ? ["center", "flex-start"]
                : ["center", "flex-end"]
            }
            borderLeftColor={"#c4c4c4"}
            borderLeftWidth={[0, 1]}
            pr={searchResultsalign ? [0, 0] : [0, 10]}
            pl={searchResultsalign ? [0, 20] : [0, 0]}
          >
            {/* @ts-ignore */}
            {(isDesktop ? currentItems : filteredArray).map((item, i) => (
              <Flex
                h={["150px", "250px"]}
                w={["150px", "300px"]}
                direction={"column"}
                m={3}
                shadow={"xl"}
                key={i}
              >
                <Flex
                  flex={3}
                  backgroundColor={item.bgcolor}
                  p={[2, 3]}
                  justify={"center"}
                  align={"center"}
                >
                  <Flex
                    h={"80%"}
                    w={"80%"}
                    background={`url(${item.image}) no-repeat center center / contain`}
                  ></Flex>
                </Flex>
                <VStack
                  flex={1.7}
                  backgroundColor={"white"}
                  p={[3, 5]}
                  align={"flex-start"}
                >
                  <Heading size={["sm", "l"]} fontStyle={"normal"}>
                    {item.title}
                  </Heading>
                  <Text fontSize={["xs", "sm"]}>{item.category}</Text>
                </VStack>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
      {/* {pagination} */}

      {isDesktop && (
        <Flex my={10} align={"center"} justify={"center"}>
          <ReactPaginate
            breakLabel="..."
            nextLabel="NEXT ->"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<- PREVIOUS"
            forcePage={forceNumber}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
          />
        </Flex>
      )}
      <Flex
        style={{
          background:
            "conic-gradient(from 5.87deg at 58.99% 0%, rgba(114, 17, 212, 0) -118.12deg, rgba(114, 17, 212, 0.56) 20.77deg, rgba(114, 17, 212, 0) 241.88deg, rgba(114, 17, 212, 0.56) 380.77deg)",
        }}
        justify={"center"}
        width={"100%"}
      >
        <Flex
          w={"80%"}
          align={"center"}
          justify={{ md: "center", base: "center" }}
        >
          <Flex
            align="center"
            // direction={{ base: "column-reverse", md: "row" }}
            w={{ base: "100%", md: "50%" }}
            py={20}
          >
            <Flex
              //   divider={<StackDivider borderColor='gray.200' />}
              gap={10}
              align={{ base: "center", md: "center" }}
              // justify={{ md: "flex-start", base: "center" }}
              // backgroundColor={"red"}
              direction={"column"}
            >
              <Heading textAlign={{ base: "center", md: "center" }}>
                Take your affiliate marketing to the next level.
              </Heading>

              <Flex alignItems={"flex-start"} gap={5}>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#000"
                    backgroundColor="#fff"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Sign up
                  </Button>
                </Link>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#fff"
                    backgroundColor="#7211D4"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Talk to a Human
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AppsIntegration;
