import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

const features = [
  <Text>
    <span style={{ fontWeight: "500" }}>Effortless Campaign Creation:</span>{" "}
    Tailor campaigns specifically for e-commerce products with features like
    tiered commission structure.
  </Text>,
  <Text>
    <span style={{ fontWeight: "500" }}>Real-Time Sales Tracking:</span>Gain
    instant insights into sales generated through your affiliate network,
    allowing you to optimize campaigns for maximum conversions.
  </Text>,
  <Text>
    <span style={{ fontWeight: "500" }}>Identify Top Performers:</span> Discover
    your most successful affiliates and leverage their strategies to drive even
    more sales.
  </Text>,
  <Text>
    <span style={{ fontWeight: "500" }}>Seamless Integrations:</span> Connect
    with leading e-commerce platforms like Shopify and WooCommerce for a
    streamlined experience.
  </Text>,
];

const FeatureCol1 = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");

  return (
    <Box maxW={{ base: "none", lg: "33%" }}>
      <Box borderLeft="1px solid #DFE7EA">
        <Text
          fontWeight="600"
          lineHeight="24px"
          color="#131316"
          fontSize="1rem"
          pl="12px"
          borderLeft="2px solid #635BFF"
        >
          Boost Your Bottom Line
        </Text>

        <Box ml="12px" pl="21px" mt="22px" borderLeft="1px solid #DFE7EA">
          {features
            .filter((_, index) => (isDesktop ? index < 3 : index < 4))
            .map((ft, index) => (
              <Box
                key={index}
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="24px"
                color="#5E5F6E"
                position="relative"
                pb={"24px"}
              >
                <Box
                  bgColor="white"
                  rounded="full"
                  h="16px"
                  w="16px"
                  position="absolute"
                  left="-28px"
                  top="0px"
                  border="0.5px solid rgba(18, 55, 105, 0.08)"
                  shadow="sm"
                />

                {ft}
              </Box>
            ))}
        </Box>
      </Box>

      {isDesktop ? (
        <Flex>
          <Box borderLeft="2px solid #635BFF" h="20px" mr="12px" />

          <Box
            ml="21px"
            fontSize="0.875rem"
            fontWeight="400"
            lineHeight="24px"
            color="#5E5F6E"
            position="relative"
          >
            <Box
              bgColor="white"
              rounded="full"
              h="16px"
              w="16px"
              position="absolute"
              border="0.5px solid rgba(18, 55, 105, 0.08)"
              left="-28px"
              top="0px"
              shadow="sm"
            />

            {features[3]}
          </Box>
        </Flex>
      ) : (
        ""
      )}
    </Box>
  );
};

export default FeatureCol1;
