import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { NavProvider } from "./context/NavContext";
// Supports weights 400-400
import "@fontsource-variable/roboto-flex";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const theme = extendTheme({
  fonts: {
    heading: "Euclid Circular A",
    body: "Euclid Circular A",
  },
  fontStyles: {
    regular: "regular",
  },
  shadows: {
    custom: "0px 5px 15px #CB98FF",
    // Add more custom shadows as needed
  },
});
root.render(
  <React.StrictMode>
    <NavProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </NavProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
