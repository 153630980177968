import {
  Box,
  Button,
  Circle,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { NavContext } from "../../context/NavContext";
import { MdSettings } from "react-icons/md";
import { FaBeer } from "react-icons/fa";
import { AdvertiserProps, AffiliateProps } from "../../helper/DummyData";
import { CgProfile } from "react-icons/cg";
import { GoTriangleRight } from "react-icons/go";
import { Link } from "react-router-dom";

const Properties2 = () => {
  const { isAdvertiser, setisAdvertiser } = useContext(NavContext);
  return (
    <Flex
      align={"center"}
      justify={"center"}
      py={20}
      bgGradient="linear(#fff 30%, #F9F9F9 70%)"
    >
      <VStack
        //   divider={<StackDivider borderColor='gray.200' />}
        spacing={4}
        align="center"
        justify={"center"}
        w={["90%", "80%"]}
        overflow={"hidden"}
      >
        <Flex
          //   h="40px"
          // bg="yellow.200"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={["24px", "30px"]} fontWeight={"600"}>
            Why Metricks?
          </Text>
        </Flex>
        <Flex
          // bg="yellow.200"
          direction={"column"}
          //   direction={{ base: "row", lg: "column" }}
          alignItems={"center"}
          justifyContent={"center"}
          gap={30}
        >
          {/* //row one */}
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
          >
            <Flex
              gap={30}
              direction={{ base: "column", lg: "row" }}
              flexWrap={{ base: "wrap", lg: "nowrap" }}
              w={"100%"}
            >
              <VStack
                align={"flex-start"}
                p={10}
                boxShadow="lg"
                rounded={"md"}
                borderRadius={20}
                gap={3}
                flex={1}
                bgColor={"#fff"}
              >
                <Heading size={"sm"}>Create an advertiser profile</Heading>
                <Text color={"#5E5F6E"} fontSize={13}>
                  Our onboarding questions are tailored to know you better and
                  give you the best affiliate recommendations to help you
                  promote your products
                </Text>
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/431001c5-3350-413b-9d8d-2620add9d7ec.svg"
                  w="100%"
                />
                {/* <Link to="https://app.metricks.io/signup">
                  <Button
                    rightIcon={<GoTriangleRight />}
                    bgColor={"#333337"}
                    color={"#c4c4c4"}
                    borderRadius={10}
                    fontWeight={"500"}
                    _hover={{
                      color: "#fff",
                      bgColor: "#c4c4c4",
                    }}
                    bgGradient="linear(#48484C 40%, #232328 60%)"
                    w={"100%"}
                  >
                    Create Account
                  </Button>
                </Link> */}
              </VStack>

              <VStack
                align={"flex-start"}
                p={10}
                boxShadow="lg"
                rounded={"md"}
                borderRadius={20}
                gap={5}
                flex={1}
                bgColor={"#fff"}
              >
                <Heading size={"sm"}>Find Affiliates</Heading>
                <Text color={"#5E5F6E"} fontSize={13}>
                  Find and pitch your campaigns to the top-ranking and
                  best-performing affiliates on metricks.
                </Text>
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/12bbbebe-e29a-40d0-bc7f-137d0629ac05.svg"
                  w="100%"
                />
              </VStack>
              <VStack
                align={"flex-start"}
                p={10}
                boxShadow="lg"
                rounded={"md"}
                borderRadius={20}
                gap={5}
                flex={1}
                bgColor={"#fff"}
              >
                <Heading size={"sm"}>Public Campaigns</Heading>
                <Text color={"#5E5F6E"} fontSize={13}>
                  Find public campaigns, see how yours compares, and list your
                  campaigns so new affiliates can find and sign up
                </Text>
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c8fd8dea-1d59-4f53-974d-c335a207d6e5.svg"
                  w="100%"
                />
              </VStack>
            </Flex>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -200 }}
            transition={{ delay: 0.25, duration: 0.8 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
          >
            <Flex
              gap={30}
              direction={{ base: "column", lg: "row" }}
              flexWrap={{ base: "wrap", lg: "nowrap" }}
              w={"100%"}
            >
              <VStack
                align={"flex-start"}
                p={10}
                boxShadow="lg"
                rounded={"md"}
                borderRadius={20}
                gap={3}
                flex={1}
                bgColor={"#fff"}
              >
                <Heading size={"sm"}>View Reports</Heading>
                <Text color={"#5E5F6E"} fontSize={13}>
                  Never miss an update with real-time reporting that keeps you
                  informed of new events in your organization and/or campaigns.
                </Text>
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/2ea2c3ce-fcba-467f-b817-47b0c0b64e36.svg"
                  w="100%"
                  h={"100%"}
                />
              </VStack>
              <VStack
                align={"flex-start"}
                p={10}
                boxShadow="lg"
                rounded={"md"}
                borderRadius={20}
                gap={5}
                flex={1}
                bgColor={"#fff"}
              >
                <Heading size={"sm"}>Switch Organizations</Heading>
                <Text color={"#5E5F6E"} fontSize={13}>
                  Find public campaigns, see how yours compares, and list your
                  campaigns so new affiliates can find and sign up
                </Text>
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/90ab16f4-b799-42d2-a37e-9a342c6d26f2.svg"
                  w="100%"
                  h={"100%"}
                />
              </VStack>
              <VStack
                align={"flex-start"}
                p={10}
                boxShadow="lg"
                rounded={"md"}
                borderRadius={20}
                gap={5}
                flex={1}
                bgColor={"#fff"}
              >
                <Heading size={"sm"}>Scale</Heading>
                <Text color={"#5E5F6E"} fontSize={13}>
                  Experience no limits - create as many organizations and
                  campaigns as you need. Find and recruit new affiliates, add
                  affiliate managers, and continue to expand your limits.
                </Text>
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/95253fa6-a5a7-4d52-87ac-bb03ee72c6da.svg"
                  w="100%"
                  h={"100%"}
                />
              </VStack>
            </Flex>
          </motion.div>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default Properties2;
