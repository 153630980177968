import React, { createContext, useState } from "react";

export const NavContext = createContext();

export function NavProvider({ children }) {
  const [isAdvertiser, setisAdvertiser] = useState(true);

  return (
    <NavContext.Provider value={{ isAdvertiser, setisAdvertiser }}>
      {children}
    </NavContext.Provider>
  );
}
