import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  ArrowForwardIcon,
  CheckIcon,
  ChevronRightIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import { BsFillPinAngleFill } from "react-icons/bs";
import { NavContext } from "../../context/NavContext";
import { useLocation } from "react-router-dom";
const AffPaddle = () => {
  const { isAdvertiser, setisAdvertiser } = useContext(NavContext);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
      >
        <Flex
          align="center"
          justify={{ base: "center", md: "space-between", xl: "space-between" }}
          direction={{ base: "column-reverse", md: "row" }}
          w={{ base: "90%", lg: "80%" }}
          //   backgroundColor={"red"}
          minH="60vh"
        >
          <Stack
            spacing={4}
            w={{ base: "100%", md: "40%" }}
            align={["center", "center", "flex-start", "flex-start"]}
            ml={isDesktop ? "20px" : "0px"}
            //   backgroundColor={"red"}
          >
            <Heading
              as="h1"
              size="2xl"
              fontWeight="700"
              color="primary.800"
              textAlign={["center", "center", "left", "left"]}
            >
              Affiliate Software For Paddle
            </Heading>

            <Text
              as="h2"
              size="md"
              color="primary.800"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["center", "center", "left", "left"]}
              //   fontFamily={"heading"}
            >
              Increase the number of high-quality sales through word-of-mouth
              marketing.
            </Text>

            {/* <Link to="https://app.metricks.io/login">
              <Button
                color="#fff"
                backgroundColor="#7211D4"
                borderRadius={50}
                lineHeight="27px"
                rightIcon={<ChevronRightIcon />}
              >
                Start Now
              </Button>
            </Link> */}
          </Stack>
          <Flex
            w={{ base: "100%", sm: "60%", md: "50%" }}
            mb={{ base: 12, md: 0 }}
            //   backgroundColor={"red"}
            justify={"flex-end"}
          >
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/16fc465a-04e6-4afb-a542-8d7a2737bf97.png"
              rounded="1rem"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex align={"center"} justify={"center"} py={20}>
        <Flex align={"center"} justify={"center"} direction={"column"} gap={7}>
          <Heading
            as="h1"
            size="xl"
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center"]}
            w={"80%"}
          >
            Begin Yor Paddle Affiliate Program With metricks
          </Heading>

          <Text
            as="h2"
            size="md"
            color="primary.800"
            opacity="0.8"
            fontWeight="400"
            lineHeight={1.5}
            textAlign={["left", "left", "center", "center"]}
            w={"60%"}
          >
            Paddle is an excellent platform for selling SAAS goods online since
            it covers subscriptions, renewals, licenses, extensions, and others.
          </Text>

          <Box
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
            shadow={"md"}
            width={"80%"}
            position={"relative"}
            p={10}
          >
            <Circle
              size="60px"
              bg="white"
              color="#7139d4"
              position={"absolute"}
              top={"-30px"}
              background="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
              _hover={{
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 12px 20px, rgba(0, 0, 0, 0.2) 0px 3px 6px",
                transform: "translateY(-2px)",
              }}
            >
              <BsFillPinAngleFill size={"25px"} />
            </Circle>
            <Text
              as="h2"
              size="md"
              color="#27374C"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["left", "left"]}
            >
              This subscription management software has various features to help
              you plan your taxes and invoicing. metricks is your best bet if
              you wish to launch a Paddle affiliate program for your Paddle
              revenue delivery service. At metricks, we provide a simple and
              easy method to do so.
            </Text>
          </Box>

          <Heading size={"md"}>
            Begin Yor Paddle Affiliate Program With metricks
          </Heading>
          <Flex
            borderTopWidth={0.5}
            borderTopColor={"#c4c4c4"}
            direction={"column"}
            width={"80%"}
            position={"relative"}
            p={10}
            gap={3}
          >
            <Heading fontSize="1.875rem">
              Recurring Or Lifetime Commission (SAAS)
            </Heading>
            <Text color="primary.800" size={"md"} opacity="0.8">
              Our SAAS commission solution offers excellent benefits to users as
              it makes payments faster using Paddle. Offer lifetime commission
              to affiliates if conversion continually renews a membership. Award
              affiliates recurring commission each time a client resubscribe
              until the selected end date.
            </Text>
          </Flex>
          <Flex
            borderTopWidth={0.5}
            borderTopColor={"#c4c4c4"}
            direction={"column"}
            width={"80%"}
            position={"relative"}
            p={10}
            gap={3}
          >
            <Heading fontSize="1.875rem">
              Fixed Or Percentage Commission
            </Heading>
            <Text color="primary.800" size={"md"} opacity="0.8">
              Customize your commission structure using metricks affiliate
              marketing software. Pay fixed or percentage affiliate commission.
            </Text>
          </Flex>
          <Flex
            borderTopWidth={0.5}
            borderTopColor={"#c4c4c4"}
            direction={"column"}
            width={"80%"}
            position={"relative"}
            p={10}
            gap={3}
          >
            <Heading fontSize="1.875rem">
              Fully-Packed Automation And Integration
            </Heading>
            <Text color="primary.800" size={"md"} opacity="0.8">
              metricks is designed just perfectly for Paddle. An API-based
              interface with your Paddle account monitors subscription payments
              and provides referral rewards automatically, adding credit to
              current subscriptions right in Paddle.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{
          background:
            "conic-gradient(from 5.87deg at 58.99% 0%, rgba(114, 17, 212, 0) -118.12deg, rgba(114, 17, 212, 0.56) 20.77deg, rgba(114, 17, 212, 0) 241.88deg, rgba(114, 17, 212, 0.56) 380.77deg)",
        }}
        justify={"center"}
        width={"100%"}
      >
        <Flex
          w={"80%"}
          align={"center"}
          justify={{ md: "center", base: "center" }}
        >
          <Flex
            align="center"
            // direction={{ base: "column-reverse", md: "row" }}
            w={{ base: "100%", md: "50%" }}
            py={20}
          >
            <Flex
              //   divider={<StackDivider borderColor='gray.200' />}
              gap={10}
              align={{ base: "center", md: "center" }}
              // justify={{ md: "flex-start", base: "center" }}
              // backgroundColor={"red"}
              direction={"column"}
            >
              <Heading textAlign={{ base: "center", md: "center" }}>
                Take your affiliate marketing to the next level.
              </Heading>

              {/* <Flex alignItems={"flex-start"} gap={5}>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#000"
                    backgroundColor="#fff"
                    shadow={"2xl"}
                    borderRadius={50}
                    lineHeight="27px"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Sign up
                  </Button>
                </Link>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#fff"
                    backgroundColor="#7211D4"
                    shadow={"2xl"}
                    borderRadius={50}
                    lineHeight="27px"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Talk to a Human
                  </Button>
                </Link>
              </Flex> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default AffPaddle;
