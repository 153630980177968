import React, { useContext } from "react";
import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link, Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";

import { NavContext } from "../../context/NavContext";
import { GoTriangleRight } from "react-icons/go";
import Lottie from "lottie-react";
import star from "./star.json";

const SaasHero = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  return (
    <Flex
      justify={"center"}
      // bgColor={"#F2F8FB"}
      //   zIndex={-1}
      background="linear-gradient(0deg, #fff +2.38%, #F3E6FF 98.36%)"
      // position={"relative"}
    >
      <Flex
        align="center"
        justify={"space-between"}
        w={{ base: "90%", lg: "80%" }}
        py={10}
        flexDir={["column", "row"]}
        gap={5}
        // background="linear-gradient(0deg, #c49bee +70%, #fff 30%)"
        position={"relative"}
        overflow={"hidden"}
      >
        <Flex flex={1} flexDir={"column"}>
          <Flex justify={["center", "flex-start"]} align={"center"}>
            <Flex
              bgColor={"#fff"}
              justify={"center"}
              align={"center"}
              borderRadius={20}
              px={5}
            >
              <Flex h={"30px"} w={"30px"}>
                <Lottie animationData={star} loop={true} />
              </Flex>
              <Text
                bgGradient="linear(to-l, #232178 10%, #DF5567 90%)"
                bgClip="text"
                fontSize="14px"
                fontWeight="extrabold"
              >
                Actionable Insights, Informed decision
              </Text>
            </Flex>
          </Flex>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            transition={{ delay: 0.3, duration: 2 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Heading
              size={["lg", "xl"]}
              textAlign={["center", "left"]}
              fontWeight={"500"}
              fontSize={["40px", "50px"]}
              mt={5}
            >
              Unleash the Power of
            </Heading>
            <Heading
              fontSize={["40px", "50px"]}
              size={["lg", "xl"]}
              textAlign={["center", "left"]}
              fontWeight={"500"}
              color={"#7218D4"}
            >
              Affiliate Marketing
            </Heading>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            transition={{ delay: 0.5 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            style={{
              alignSelf: "center",
              // backgroundColor: "red",
              width: isDesktop ? "100%" : "90%",
            }}
          >
            <Text
              w={["100%", "100%"]}
              fontSize={"15px"}
              color={"#5E5F6E"}
              textAlign={["center", "left"]}
              mt={10}
            >
              Launch Your Affiliate Program in 5 minutes, Track, Optimize, and
              Measure the Performance of your Affiliate Programs with Ease
            </Text>
          </motion.div>
          <Flex
            gap={5}
            flexDir={["column", "row"]}
            w={isDesktop ? "" : "100%"}
            // justify={"center"}
            mt={5}
          >
            <Link to="https://cal.com/metricks/15min">
              <Button
                rightIcon={<GoTriangleRight />}
                color={"#fff"}
                borderRadius={10}
                fontWeight={"500"}
                boxShadow="custom"
                w={isDesktop ? "" : "100%"}
                _hover={{
                  color: "#fff",
                  //   bgColor: "#c4c4c4",
                }}
                zIndex={1}
                bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
              >
                Book a Demo
              </Button>
            </Link>
            {/* <Link to="https://app.metricks.io/signup">
              <Button
                rightIcon={<GoTriangleRight />}
                color={"#000"}
                borderRadius={10}
                fontWeight={"500"}
                shadow={"base"}
                w={isDesktop ? "" : "100%"}
                cursor={"pointer"}
                _hover={{
                  color: "#c4c4c4",
                  //   bgColor: "#c4c4c4",
                }}
                bgColor={"#fff"}
                zIndex={1}
              >
                Launch Your Growth Engine Now
              </Button>
            </Link> */}
          </Flex>
        </Flex>
        <Flex flex={1}>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            transition={{ delay: 0.3, duration: 2 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            style={{ flex: 1 }}
          >
            <Image src="/assets/svg/pcWall.svg" flex={1} />
          </motion.div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SaasHero;
