import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { LiaBullseyeSolid } from "react-icons/lia";
import { Swiper, SwiperSlide } from "swiper/react";
import { GiStarShuriken } from "react-icons/gi";
import { GoTriangleRight } from "react-icons/go";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
const data = [
  {
    heading: "Is Metricks free to use?",
    detail:
      "Metricks offers a 14-day free trial to explore the platform and its features. Paid subscription plans are available for ongoing program management.",
  },
  {
    heading: "How can Metricks help me increase my sales?",
    detail:
      "Metricks empowers you to build a network of passionate affiliates who promote your products or services, expanding your reach and driving more sales.",
  },
  {
    heading:
      "Do I need any prior experience with affiliate marketing to use Metricks?",
    detail:
      "Metricks is designed to be user-friendly for beginners and experienced affiliate marketers. We also offer comprehensive resources and support to help you get started.",
  },
  {
    heading:
      "How long does setting up an affiliate program with Metricks take?",
    detail:
      "Setting up a basic program with Metricks is quick and easy. The time it takes will depend on the complexity of your program and campaign settings, but it takes approximately 5 minutes.",
  },
  {
    heading: "How does Metricks help me find high-performing affiliates?",
    detail:
      "Metricks offers powerful search filters and a vast affiliate network to help you connect with affiliates perfectly aligned with your brand and target audience.",
  },
  {
    heading: "Can I automate tasks like payouts and commission calculations?",
    detail:
      "Absolutely! Metricks automates many tedious tasks, freeing you up to focus on strategic program growth",
  },
];
const Faq = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const [faq, setFaq] = useState(false);
  const [className, setClassName] = useState(null);
  return (
    <Flex
      justify={"center"}
      align={"center"}
      bgColor={isDesktop ? "#fff" : "#F3E7FF"}
      w={"100%"}
    >
      <Flex
        align="center"
        justify={"center"}
        w={"95%"}
        py={20}
        flexDir={"column"}
        gap={5}
        position={"relative"}
        borderRadius={50}
        overflow={"hidden"}
        // bgColor={"#EAF4FE"}
        bgGradient={"linear(to-b, #F3E7FF, #F9F3FE)"}
      >
        {" "}
        <Flex
          align={"center"}
          gap={3}
          px={5}
          py={2}
          bgColor={"#fff"}
          borderWidth={1}
          borderColor={"#c4c4c4"}
          shadow={"base"}
          borderRadius={50}
        >
          <GiStarShuriken fontSize={20} />
          <Text fontSize={15}>FAQs</Text>
        </Flex>
        <Text fontWeight={"600"} fontSize={35} textAlign={["center", "center"]}>
          Frequently Asked Questions (FAQs)
        </Text>
        <Text
          fontWeight={"300"}
          fontSize={17}
          textAlign={"center"}
          w={["100%", "40%"]}
        >
          See how real businesses use Metricks to achieve remarkable results
          with affiliate marketing.
        </Text>
        {faq ? (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            transition={{ delay: 0.3, duration: 2 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            style={{ width: isDesktop ? "60%" : "90%" }}
          >
            <Accordion
              w={"100%"}
              bgColor={"white"}
              borderRadius={10}
              allowToggle
            >
              {data.map((item, i) => (
                <AccordionItem key={i}>
                  <h2>
                    <AccordionButton h={20}>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize={17} fontWeight={"600"}>
                          {item.heading}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{item.detail}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </motion.div>
        ) : (
          <Button
            rightIcon={<GoTriangleRight />}
            color={"#fff"}
            borderRadius={10}
            fontWeight={"500"}
            boxShadow="custom"
            _hover={{
              color: "#fff",
              //   bgColor: "#c4c4c4",
            }}
            bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
            mt={10}
            onClick={() => setFaq(true)}
          >
            View all Questions
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Faq;
