import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsFillPauseFill } from "react-icons/bs";
import { GoTriangleRight } from "react-icons/go";

const users = [
  "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/6a2132a4-a025-4097-b17c-59e5ee8e837c.svg",
  "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1431b4a5-cca8-4e29-b10d-376ef4752189.svg",
  "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/e6c57672-7659-492f-89db-8022cbcbc50d.svg",
  "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/d8649847-e048-495f-97fc-ee5cad4a5f92.svg",
  "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/440990c4-6899-4723-9960-ebde63ec038e.svg",
  "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/6e6de605-9c32-4653-9af5-bd2a27eaf79b.svg",
];

const Explainer = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [curentState, setcurentState] = useState("pause");
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const [vidTime, setvidTime] = useState(0);
  const [progress, setprogress] = useState(0);

  const videoHandler = (control: string) => {
    if (videoRef) {
      if (control === "play") {
        videoRef?.current?.play();
        setvidTime(videoRef?.current?.duration || 0);
      } else if (control === "pause") {
        videoRef?.current?.pause();
      }
      setcurentState(control);
    }
  };

  useEffect(() => {
    if (vidTime && videoRef && curentState === "play") {
      window.setInterval(function () {
        // @ts-ignore
        setprogress((videoRef.current?.currentTime / vidTime) * 100);
      }, 1000);
    }
  }, [curentState]);

  return (
    <Box className="w-full">
      <Image
        src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/31dae14f-bfaf-4ee3-8f94-e76cb899904a.svg"
        alt="top border"
        w="100%"
        h="auto"
      />

      <Box
        className="w-full"
        overflowX="hidden"
        overflowY="hidden"
        h="max"
        position="relative"
        bg="rgba(19, 21, 21, 1)"
        paddingBottom="80px"
      >
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/49e9c42d-281b-4374-97b9-d882b5e7d9ef.svg"
          alt="light"
          w="80%"
          h="auto"
          position="absolute"
          left="0px"
          top="0px"
        />

        <Flex
          direction="column"
          alignItems="center"
          w={{ base: "90%", lg: "80%" }}
          mx="auto"
          mt="80px"
          maxW="940px"
        >
          <Text
            fontWeight={"600"}
            fontSize="1.875rem"
            textAlign={"center"}
            lineHeight="40px"
            color="#FFFFFF"
          >
            Your All-in-one Affiliate Marketing Command Center
          </Text>
          <Text
            fontWeight={"400"}
            fontSize={"0.9rem"}
            textAlign={"center"}
            mt="15px"
            color="rgba(147, 148, 161, 1)"
          >
            The solution you need to grow and scale your affiliate marketing.
            See how Metricks can take your business to the next level with
            seamless affiliate marketing tools.
          </Text>
        </Flex>

        <Box
          position="relative"
          w={{ base: "90%", md: "90%" }}
          rounded="26px"
          my="60px"
          mx="auto"
          border="1.8px solid rgba(255, 255, 255, 0.04)"
          className="explainer-video-bg"
          px="20px"
          py="20px"
          maxW="1042px"
        >
          <iframe
            className="explainer-iframe"
            src="https://www.youtube.com/embed/vSnzsx1nOp8?controls=1"
          ></iframe>

          {/* <video
            id="video"
            ref={videoRef}
            poster="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/04ec3c09-e875-4f54-b531-ceb86699027f.svg"
            style={{
              height: "auto",
              width: "100%",
              borderRadius: "12px",
              opacity: curentState === "play" ? "1" : "0.87",
            }}
            src="/assets/videos/explainer.mp4"
          ></video> */}

          {/* {curentState === "play" ? (
            <BsFillPauseFill
              style={{
                fontSize: isDesktop ? "6rem" : "4.75rem",
                color: "grey",
                position: "absolute",
                zIndex: 3,
                left: isDesktop ? "45%" : "40%",
                top: isDesktop ? "45%" : "40%",
                cursor: "pointer",
              }}
              onClick={() => videoHandler("pause")}
            />
          ) : (
            <Image
              height={{ base: "70px", md: "120px" }}
              width={{ base: "70px", md: "120px" }}
              cursor="pointer"
              position="absolute"
              top={isDesktop ? "45%" : "40%"}
              left={isDesktop ? "45%" : "40%"}
              zIndex={2}
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/84711bbc-c187-4cd1-97bd-37add09b7466.svg"
              alt="play"
              onClick={() => videoHandler("play")}
            />
          )} */}
        </Box>

        <Flex direction="column" alignItems="center">
          <Box display="flex" rowGap="8px" alignItems="center">
            {users.map((imgUrl, index) => (
              <Image src={imgUrl} alt={"user " + index} height="28px" />
            ))}
          </Box>

          <Text
            fontWeight="400"
            fontSize={"0.9rem"}
            lineHeight="20px"
            textAlign={"center"}
            mt="16px"
            mb="24px"
            color="rgba(147, 148, 161, 1)"
          >
            Join the winning club. Launch your affiliate program in 5 minutes
          </Text>

          {/* <Button
            rightIcon={<GoTriangleRight style={{ opacity: "0.8" }} />}
            color={"#fff"}
            borderRadius={10}
            fontWeight={"500"}
            w="max"
            onClick={() =>
              window.open(
                "https://app.metricks.io/signup",
                "_blank"
                // "rel=noopener noreferrer"
              )
            }
            _hover={{
              color: "#fff",
            }}
            zIndex={1}
            bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
          >
            Start Now
          </Button> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default Explainer;
