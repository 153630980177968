import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  ArrowForwardIcon,
  CheckIcon,
  ChevronRightIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import { BsFillPinAngleFill } from "react-icons/bs";
import { NavContext } from "../../context/NavContext";
import { useLocation } from "react-router-dom";
const AffLightspeed = () => {
  const { isAdvertiser, setisAdvertiser } = useContext(NavContext);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const gridData = [
    {
      description: "14-day free trial.",
    },
    {
      description: "Excellent customer service. ",
    },
    {
      description: "Its clever anti-fraud tools detect fraud.",
    },
    {
      description: "Its commission structure is entirely configurable.",
    },
    {
      description: "metricks allows you to customize the affiliate dashboard.",
    },
    {
      description: "You can communicate with your affiliates via in-app chat.",
    },
    {
      description:
        "Within five minutes, you can launch your affiliate programs.",
    },
    {
      description:
        "It is adaptable to different eCommerce businesses and SaaS.",
    },
    {
      description:
        "Easily track, and evaluate affiliate marketing performance.",
    },
    {
      description:
        "With a single sign-up, you can access all of your marketing campaigns.",
    },
    {
      description:
        "One can link with third-party KPIs without having any technical experience.",
    },
    {
      description:
        "Uploading and sharing creative materials (images and videos) to affiliates' social media profiles.",
    },
  ];

  interface FeatureProps {
    title: string;
    text: string;
    icon: React.ReactElement;
  }

  const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
      <Stack borderWidth={0.5} borderColor={"#c4c4c4"} p={10}>
        <Flex
          //   w={16}
          //   h={16}
          align={"center"}
          justify={"center"}
          //   color={"white"}
          rounded={"full"}
          //   bg={"gray.100"}
          mb={1}
          p={5}
        >
          <Circle bgColor={"#eee2fa"} p={1.5}>
            {icon}
          </Circle>
        </Flex>

        <Text color={"gray.600"} textAlign={"center"}>
          {text}
        </Text>
      </Stack>
    );
  };

  return (
    <>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
      >
        <Flex
          align="center"
          justify={{ base: "center", md: "space-between", xl: "space-between" }}
          direction={{ base: "column-reverse", md: "row" }}
          w={{ base: "90%", lg: "80%" }}
          //   backgroundColor={"red"}
          minH="60vh"
        >
          <Stack
            spacing={4}
            w={{ base: "100%", md: "40%" }}
            align={["center", "center", "flex-start", "flex-start"]}
            ml={isDesktop ? "20px" : "0px"}
            //   backgroundColor={"red"}
          >
            <Heading
              as="h1"
              size="2xl"
              fontWeight="700"
              color="primary.800"
              textAlign={["center", "center", "left", "left"]}
            >
              Affiliate Software For Lightspeed
            </Heading>

            <Text
              as="h2"
              size="md"
              color="primary.800"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["center", "center", "left", "left"]}
              //   fontFamily={"heading"}
            >
              Launching an affiliate marketing program doesn't have to be
              challenging. metricks' new integration with Lightspeed helps you
              kickstart an affiliate program for your online shop within a few
              minutes!
            </Text>

            <Link to="https://app.metricks.io/login">
              <Button
                color="#fff"
                backgroundColor="#7211D4"
                borderRadius={50}
                py="7"
                px="6"
                lineHeight="1"
                size="md"
                rightIcon={<ChevronRightIcon />}
              >
                Start Now
              </Button>
            </Link>
          </Stack>
          <Flex
            w={{ base: "100%", sm: "60%", md: "50%" }}
            mb={{ base: 12, md: 0 }}
            //   backgroundColor={"red"}
            justify={"flex-end"}
          >
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/9b0ed784-59c4-411f-a446-057ba60b6166.png"
              rounded="1rem"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex align={"center"} justify={"center"} mb={30} py={20}>
        <Flex align={"center"} justify={"center"} direction={"column"} gap={7}>
          <Heading
            as="h1"
            size="xl"
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center"]}
            w={"80%"}
          >
            So, why do most online stores and websites don't have an active
            affiliate program?
          </Heading>

          <Text
            as="h2"
            size="md"
            color="primary.800"
            opacity="0.8"
            fontWeight="400"
            lineHeight={1.5}
            textAlign={["left", "left", "center", "center"]}
            w={"60%"}
          >
            One of the reasons is that they're not sure how to set up an
            affiliate program from scratch as it involves obtaining affiliate
            links and tracking and rewarding affiliates. Also, they lack ideas
            on how to discover affiliates to work with. Here's where metricks
            comes in!
          </Text>

          <Box
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
            shadow={"md"}
            width={"80%"}
            position={"relative"}
            p={10}
          >
            <Circle
              size="60px"
              bg="white"
              color="#7139d4"
              position={"absolute"}
              top={"-30px"}
              background="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
              _hover={{
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 12px 20px, rgba(0, 0, 0, 0.2) 0px 3px 6px",
                transform: "translateY(-2px)",
              }}
            >
              <BsFillPinAngleFill size={"25px"} />
            </Circle>
            <Text
              as="h2"
              size="md"
              color="#27374C"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["left", "left"]}
            >
              Our software is highly compatible with Lightspeed. We make the
              process smooth so you can expand your Lightspeed store
              immediately. All you need do is integrate it with a single click
              without any coding experience or tech skillsets, and your
              affiliate program will be up and running in minutes
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Flex align={"center"} justify={"center"} direction={"column"} gap={7}>
        <Flex
          borderTopWidth={0.5}
          borderTopColor={"#c4c4c4"}
          direction={"column"}
          width={"80%"}
          position={"relative"}
          p={10}
          gap={3}
        >
          <Heading size={"md"}>How Do metricks Function?</Heading>

          <>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Recruit affiliates
              </Text>
            </Flex>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Everyone can effortlessly switch over from your current
                affiliate platform so your Ecwid store may expand as soon as
                possible.
              </Text>
            </Flex>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                You can always count on our customer success team to guide you
                through the onboarding process and answer any questions you may
                have.
              </Text>
            </Flex>

            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Create an eye-catching onboarding page – everything about your
                program, from referral links to affiliate dashboards, can be
                tailored to your brand and domain.
              </Text>
            </Flex>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#fff"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Ready to get started? Sign up with metricks today!
              </Text>
            </Flex>
          </>
        </Flex>
      </Flex>

      <Flex
        align={"center"}
        justify={"center"}
        direction={"column"}
        gap={10}
        my={10}
      >
        <Heading size={"md"}>
          Benefits of Using metricks Affiliate Software For Lightspeed
        </Heading>
        <Box w={"80%"}>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            // spacing={10}
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
          >
            {gridData.map((item, i) => (
              <Feature
                icon={<CheckIcon color={"#7211D4"} fontSize={12} w={5} h={5} />}
                title={"Lifetime Support"}
                text={item.description}
                key={i}
              />
            ))}
          </SimpleGrid>
        </Box>
      </Flex>

      <Flex
        style={{
          background:
            "conic-gradient(from 5.87deg at 58.99% 0%, rgba(114, 17, 212, 0) -118.12deg, rgba(114, 17, 212, 0.56) 20.77deg, rgba(114, 17, 212, 0) 241.88deg, rgba(114, 17, 212, 0.56) 380.77deg)",
        }}
        justify={"center"}
        width={"100%"}
      >
        <Flex
          w={"80%"}
          align={"center"}
          justify={{ md: "center", base: "center" }}
        >
          <Flex
            align="center"
            // direction={{ base: "column-reverse", md: "row" }}
            w={{ base: "100%", md: "50%" }}
            py={20}
          >
            <Flex
              //   divider={<StackDivider borderColor='gray.200' />}
              gap={10}
              align={{ base: "center", md: "center" }}
              // justify={{ md: "flex-start", base: "center" }}
              // backgroundColor={"red"}
              direction={"column"}
            >
              <Heading textAlign={{ base: "center", md: "center" }}>
                Take your affiliate marketing to the next level.
              </Heading>

              <Flex alignItems={"flex-start"} gap={5}>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#000"
                    backgroundColor="#fff"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Sign up
                  </Button>
                </Link>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#fff"
                    backgroundColor="#7211D4"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Talk to a Human
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default AffLightspeed;
