import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";

import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { BsFillPauseFill } from "react-icons/bs";

const MediaPlayer = ({
  reviews,
  onClose,
  activeIndex,
}: {
  reviews: any[];
  activeIndex?: number;
  onClose: () => void;
}) => {
  const sliderRef = useRef<any>(null);
  const [isStart, setisStart] = useState(activeIndex === 0 ? true : false);
  const [isEnd, setisEnd] = useState(
    reviews.length === 0 || !activeIndex || activeIndex + 1 === reviews.length
      ? true
      : false
  );
  const [activeSlide, setactiveSlide] = useState<number>(activeIndex || 0);
  const videoRef = useRef<(HTMLVideoElement | null)[]>([]);
  const [curentState, setcurentState] = useState("pause");
  const [vidTime, setvidTime] = useState(0);
  const [progress, setprogress] = useState(0);

  const videoHandler = (control: string, index: number) => {
    if (videoRef) {
      if (control === "play") {
        videoRef?.current[index]?.play();
        setvidTime(videoRef?.current[index]?.duration || 0);
      } else if (control === "pause") {
        videoRef?.current[index]?.pause();
      }
      setcurentState(control);
    }
  };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    sliderRef?.current?.swiper.update();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    sliderRef?.current?.swiper.update();
  }, []);

  useEffect(() => {
    if (
      vidTime &&
      videoRef &&
      videoRef?.current[activeSlide] &&
      curentState === "play"
    ) {
      window.setInterval(function () {
        // @ts-ignore
        setprogress(
          ((videoRef.current[activeSlide]?.currentTime || 0) / vidTime) * 100
        );
      }, 1000);
    }
  }, [curentState]);

  return (
    <Box
      position="fixed"
      top="0px"
      left="0px"
      height="100vh"
      width="100vw"
      bg="rgba(0, 0, 0, 0.1)"
      zIndex="5"
      className="backdropFlter"
    >
      <Flex w="100%" height="100%" alignItems="center" justifyContent="center">
        <Box
          w="36px"
          h="36px"
          bg="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          boxShadow="0px 2px 4px 0px rgba(18, 55, 105, 0.08)"
          cursor="pointer"
          rounded="full"
          mr={{ base: "5%", md: "150px" }}
          onClick={handlePrev}
        >
          <IoChevronBackOutline color="#11244A" size="14px" />
        </Box>

        <Box maxW={{ base: "60%", md: "20%" }}>
          <Flex justifyContent="end" w="100%" mb="16px">
            <Box
              w="36px"
              h="36px"
              bg="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
              boxShadow="0px 2px 4px 0px rgba(18, 55, 105, 0.08)"
              cursor="pointer"
              rounded="full"
              onClick={onClose}
            >
              <MdOutlineClose color="#11244A" size="14px" />
            </Box>
          </Flex>

          <Swiper
            slidesPerView={1}
            spaceBetween={2}
            onSlideChange={(swiper: any) => {
              if (swiper) {
                setisEnd(swiper?.isEnd || false);
                setisStart(swiper?.isBeginning || false);
                setactiveSlide(swiper?.activeIndex || 0);
                setvidTime(0);
                setcurentState("pause");
                setprogress(0);
              }
            }}
            ref={sliderRef}
            modules={[EffectCreative]}
            grabCursor={true}
            effect={"creative"}
            id="mediaPlayerSlider"
            creativeEffect={{
              prev: {
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            initialSlide={activeIndex || 0}
          >
            {reviews.map((item, i) => (
              <SwiperSlide key={i}>
                <Flex
                  flexDir={"column"}
                  padding="12px"
                  rounded="24px"
                  className="mediaGradient"
                  boxShadow="0px 2px 4px 0px rgba(18, 55, 105, 0.08)"
                  opacity={activeSlide === i ? "1" : "0"}
                >
                  <Box position="relative" w="100%" h={"max"}>
                    {activeSlide === i ? (
                      <video
                        id="video"
                        poster={item.cover || undefined}
                        ref={(el) => (videoRef.current[i] = el)}
                        style={{
                          height: "auto",
                          width: "100%",
                          //   borderRadius: "12px",
                        }}
                        src={item.video}
                      ></video>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Flex
                    w="100%"
                    bg="white"
                    borderBottomRadius="16px"
                    p="12px"
                    alignItems="center"
                  >
                    <Box
                      h="6px"
                      bg="rgba(102, 103, 250, 0.31)"
                      flexGrow="1"
                      mr="15px"
                    >
                      <Box
                        h="full"
                        bg="rgba(102, 103, 250, 1)"
                        style={{ width: `${progress}%` }}
                      />
                    </Box>

                    {curentState === "play" ? (
                      <BsFillPauseFill
                        style={{
                          fontSize: "1.25rem",
                          color: "grey",

                          cursor: "pointer",
                        }}
                        onClick={() => videoHandler("pause", i)}
                      />
                    ) : (
                      <Image
                        height="24px"
                        width="24px"
                        cursor="pointer"
                        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/84711bbc-c187-4cd1-97bd-37add09b7466.svg"
                        alt="play"
                        onClick={() => videoHandler("play", i)}
                      />
                    )}
                  </Flex>
                </Flex>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Box
          w="36px"
          h="36px"
          bg="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          rounded="full"
          boxShadow="0px 2px 4px 0px rgba(18, 55, 105, 0.08)"
          cursor="pointer"
          ml={{ base: "5%", md: "150px" }}
          onClick={handleNext}
        >
          <IoChevronForwardOutline color="#11244A" size="14px" />
        </Box>
      </Flex>
    </Box>
  );
};

export default MediaPlayer;
