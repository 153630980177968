import { Box } from "@chakra-ui/react";
import React from "react";
import Hero from "../components/Saas/Hero";
import Partners from "../components/Saas/Partners";
import Features from "../components/Saas/Features";
import Comments from "../components/Comments/Comments";
import StillQuestions from "../components/Saas/StillQuestions";

const ForSaas = () => {
  return (
    <Box maxWidth="100%" w="100%">
      <Hero />
      <Partners />
      <Features />
      <Comments />
      <StillQuestions />
    </Box>
  );
};

export default ForSaas;
