import React, { useContext } from "react";
import { NavContext } from "../context/NavContext";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  ArrowForwardIcon,
  ChevronRightIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import Hero from "../components/Hero/Hero";
import IntegrationWith from "../components/IntegrationWith/IntegrationWith";
import Properties from "../components/MetrickProperties/Properties";
import Pricing from "../components/Pricing/Pricing";
import SignupAds from "../components/SignupAds/SignupAds";
import ManageAffiliates from "../components/ManageAffiliatesBanner/ManageAffiliates";
import Hero2 from "../components/Hero/Hero2";
import Properties2 from "../components/MetrickProperties/Properties2";
import Insights from "../components/Insights/Insights";
import Integrations from "../components/IntegrationWith/Integrations";
import Penetrate from "../components/Penetrate/Penetrate";
import Comments from "../components/Comments/Comments";
import Faq from "../components/Faq/Faq";
import Still from "../components/Still/Still";
import Explainer from "../components/Explainer/Explainer";
import SaasHero from "../components/Hero/SaasHero";

function ForSaas() {
  const { isAdvertiser } = useContext(NavContext);

  return (
    <>
      <SaasHero />
      {/* <Properties2 /> */}
      <Insights />
      <Explainer />
      <Integrations />
      <Penetrate />
      <Comments />
      <Faq />
      <Still />
    </>
  );
}

export default ForSaas;
