import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

const Partners = () => {
  return (
    <Box
      w="100%"
      pt={{ base: "105px", lg: "156px" }}
      pb={{ base: "61px", lg: "81px" }}
      overflowX="hidden"
      overflowY="visible"
      px={{ base: 5, lg: 0 }}
    >
      <Flex
        w={{ md: "85%" }}
        flexDir={{ base: "column", lg: "row" }}
        alignItems={{ base: "center", lg: "start" }}
        mx="auto"
      >
        <Text
          textAlign={["center", "start"]}
          maxW="196px"
          color="#131316"
          fontSize="0.9rem"
          lineHeight="24px"
          mr={{ lg: "40px" }}
          fontWeight="400"
          mb={{ base: "16px", lg: "0px" }}
        >
          Trusted by fast-growing companies around the world
        </Text>

        <motion.div
          initial={{ opacity: 0, x: 200 }}
          transition={{ delay: 0.3, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          style={{ flex: 4, zIndex: 1 }}
        >
          <Flex
            justify={{ base: "center", lg: "space-between" }}
            alignItems={"center"}
            flexWrap={["wrap", "nowrap"]}
            gap={5}
          >
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/ed37ccb1-7447-4b57-a342-a4af8d8c8d80.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d3b4105b-b083-41de-aa62-f63589a78db2.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5d39a83e-b588-4cda-8d15-682b3fc9b23a.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d33168b2-1965-48eb-87c6-a9ba9d020fb8.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/9554e67e-08a9-4998-a42c-0e8217395c22.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a186a6eb-e1e3-4b82-a6e3-d5c2062562f8.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7fe57aee-7abf-45f1-b304-65c67d980b35.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
            <Image
              src="/assets/svg/saas.svg"
              h={{ base: "22px", md: "25px" }}
              borderWidth={{ lg: 3 }}
              borderColor={"#000"}
            />
          </Flex>
        </motion.div>
      </Flex>
    </Box>
  );
};

export default Partners;
