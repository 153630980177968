import React, { useContext } from "react";
import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link, Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";

import { NavContext } from "../../context/NavContext";
import { GoTriangleRight } from "react-icons/go";

const Hero2 = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  return (
    <Flex
      justify={"center"}
      bgColor={"#F2F8FB"}
      zIndex={-1}
      // position={"relative"}
    >
      <Flex
        align="center"
        justify={"center"}
        w={{ base: "90%", lg: "80%" }}
        py={10}
        flexDir={"column"}
        gap={5}
        bgColor={"#F2F8FB"}
        position={"relative"}
        overflow={"hidden"}
      >
        <Flex
          w="100%"
          position={"absolute"}
          justify={"center"}
          align={"center"}
          //   bgColor={"red"}
        >
          <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5a58d44d-4eaa-461c-bec3-91531853c020.svg" />
        </Flex>
        <Flex
          w="100%"
          position={"absolute"}
          justify={"center"}
          align={"center"}
          // bgColor={"red"}
          zIndex={1}
        >
          <Image src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dfc24d15-c5b5-44fe-ba98-4678a09cc482.svg" />
        </Flex>
        {isDesktop && (
          <Flex
            h={["40px", "70px"]}
            w={["40px", "70px"]}
            bgColor={"#fff"}
            position={"absolute"}
            left={[2, 10]}
            top={["35%", "40%"]}
            justify={"center"}
            align={"center"}
            borderRadius={50}
            shadow={"base"}
            zIndex={2}
          >
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/f7453245-3ca0-49e5-b43e-2a5175987859.png"
              // w="100px"
              h={["30px", "60px"]}
              borderWidth={3}
              borderColor={"#000"}
            />
          </Flex>
        )}

        {isDesktop && (
          <Flex
            zIndex={2}
            h={["40px", "70px"]}
            w={["40px", "70px"]}
            bgColor={"#fff"}
            position={"absolute"}
            right={[2, 10]}
            top={["35%", "40%"]}
            justify={"center"}
            align={"center"}
            borderRadius={50}
            shadow={"base"}
          >
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/fff96ea8-7280-4092-bdcf-ea7eee281ad4.png"
              // w="100px"
              h={["30px", "60px"]}
              borderWidth={3}
              borderColor={"#000"}
            />
          </Flex>
        )}

        <motion.div
          initial={{ opacity: 0, y: 300 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          style={{ zIndex: 1 }}
        >
          <Heading
            // size={["2xl", "3xl"]}
            fontSize={["36px", "67px"]}
            textAlign={"center"}
            my={5}
            fontFamily={"Inter"}
            fontWeight={"700"}
          >
            Unleash the Power of Affiliate Marketing
          </Heading>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          style={{
            alignSelf: "center",
            // backgroundColor: "red",
            width: isDesktop ? "60%" : "90%",
            zIndex: 1,
          }}
        >
          <Text
            w={["100%", "100%"]}
            textAlign={"center"}
            color={"#5E5F6E"}
            fontSize={["14px", "16px"]}
            fontFamily={"Inter"}
            fontWeight={"400"}
          >
            Launch Your Affiliate Program in 5 minutes, Track, Optimize, and
            Measure the Performance of your Affiliate Programs with Ease
          </Text>
        </motion.div>

        <Flex
          gap={5}
          flexDir={isDesktop ? "row" : "column"}
          w={isDesktop ? "" : "80%"}
          mt={5}
        >
          <Link to="https://cal.com/metricks/15min">
            <Button
              rightIcon={<GoTriangleRight />}
              color={"#fff"}
              borderRadius={10}
              fontWeight={"500"}
              boxShadow="custom"
              w={isDesktop ? "" : "100%"}
              _hover={{
                color: "#fff",
                //   bgColor: "#c4c4c4",
              }}
              zIndex={1}
              bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
            >
              Book a Demo
            </Button>
          </Link>
          {/* <Link to="https://app.metricks.io/signup">
            <Button
              rightIcon={<GoTriangleRight />}
              color={"#000"}
              borderRadius={10}
              fontWeight={"500"}
              shadow={"base"}
              w={isDesktop ? "" : "100%"}
              cursor={"pointer"}
              _hover={{
                color: "#c4c4c4",
                //   bgColor: "#c4c4c4",
              }}
              bgColor={"#fff"}
              zIndex={1}
              fontSize={"16px"}
            >
              Launch Your Growth Engine Now
            </Button>
          </Link> */}
        </Flex>

        <Flex w={"100%"} gap={5} mt={10} flexDir={isDesktop ? "row" : "column"}>
          <Flex flex={1} zIndex={1}>
            <Text textAlign={["center", "unset"]}>
              Trusted by fast-growing companies around the world
            </Text>
          </Flex>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            style={{ flex: 4, zIndex: 1 }}
          >
            <Flex
              // flex={4}
              justify={"space-between"}
              align={"center"}
              flexWrap={["wrap", "nowrap"]}
              gap={5}
            >
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/ed37ccb1-7447-4b57-a342-a4af8d8c8d80.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d3b4105b-b083-41de-aa62-f63589a78db2.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/5d39a83e-b588-4cda-8d15-682b3fc9b23a.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/d33168b2-1965-48eb-87c6-a9ba9d020fb8.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/9554e67e-08a9-4998-a42c-0e8217395c22.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/a186a6eb-e1e3-4b82-a6e3-d5c2062562f8.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
              <Image
                src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7fe57aee-7abf-45f1-b304-65c67d980b35.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
              <Image
                src="/assets/svg/saas.svg"
                // w="100px"
                h={"25px"}
                borderWidth={3}
                borderColor={"#000"}
              />
            </Flex>
          </motion.div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Hero2;
