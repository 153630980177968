import {
  Avatar,
  Box,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LiaBullseyeSolid } from "react-icons/lia";
import { Swiper, SwiperSlide } from "swiper/react";

import { GiStarShuriken } from "react-icons/gi";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
// import required modules
import "./styles.css";
import { EffectCards } from "swiper/modules";
import { BsFillPauseFill } from "react-icons/bs";
import MediaPlayer from "../mediaPlayer/MediaPlayer";

const comments = [
  // {
  //   video:
  //     "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/698373f9-9744-486c-ad06-a2d12ec5947d.mp4",
  //   cover: "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/dcf97272-a428-43cd-9d80-a71abd4c6f0b.png",
  //   review:
  //     "Metricks has completely transformed my affiliate marketing business!",
  //   user: "Calista Gonzales",
  // },
  {
    video:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7ce16114-5ecd-4471-93ca-96390c8e4bf3.mp4",
    cover:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/06649c39-4872-4f16-97f8-b873ff8282d3.png",
    review: "Metricks was the game changer that helped our business grow",
    user: "Lisa Zupan",
    role: "CEO & Founder of SME",
  },
  {
    video:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/c95691ac-9550-4ba7-9950-ff6f37b5a0b8.mp4",
    cover:
      "https://metricksassets.s3.us-east-2.amazonaws.com/production/default/051eb475-6cf7-4eaa-bb0e-28c0d8664f6b.png",
    review:
      "Metricks has allowed us to build an affiliate partner base that actively drives sales",
    user: "Kerby Joe Grubb",
    role: "CEO & Founder of KJB",
  },
  {
    video:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/aac33358-af78-4dcd-a680-4a617585e87d.mp4",
    cover:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/09cfd591-b338-4750-bf9b-f5e51a9ce125.png",
    review:
      "I earned big commissions using Metricks to manage my affiliate marketing business",
    user: "Calista Gonzales",
    role: "Affiliate Marketer",
  },
];

const Comments = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const sliderRef = useRef<any>(null);

  const [activeSlide, setactiveSlide] = useState(0);
  const [openMediaPlayer, setopenMediaPlayer] = useState(false);
  const [currentViewedReview, setcurrentViewedReview] = useState(0);
  const [curentState, setcurentState] = useState("pause");

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    sliderRef?.current?.swiper.update();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    sliderRef?.current?.swiper.update();
  }, []);

  return (
    <Flex justify={"center"} bgColor={"#fff"} w={"100%"} py={3}>
      <Flex
        align="center"
        justify={"center"}
        w={"95%"}
        pt={{ base: "105px", lg: 20 }}
        pb={{ base: "65px", lg: 20 }}
        flexDir={"column"}
        position={"relative"}
        borderRadius={50}
        overflow={"hidden"}
        bgColor={"#F8F1FF"}
      >
        {isDesktop ? (
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/2f93490c-9b69-488e-91f7-0be1106f8cbc.svg"
            position={"absolute"}
            top={0}
            left={-1}
            h={{ lg: "25%" }}
          />
        ) : (
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dca985a9-47a9-4c41-ae6c-e4d0c80fcd18.svg"
            position={"absolute"}
            top="-60px"
            left="-5%"
            w="70px"
          />
        )}

        {isDesktop && (
          <Image
            src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/2f93490c-9b69-488e-91f7-0be1106f8cbc.svg"
            position={"absolute"}
            bottom={0}
            right={-1}
            h={"25%"}
          />
        )}

        <Flex
          align={"center"}
          gap={3}
          px={5}
          py={2}
          bgColor={"#fff"}
          borderWidth={1}
          borderColor={"#c4c4c4"}
          shadow={"base"}
          borderRadius={50}
          mb="20px"
        >
          <GiStarShuriken fontSize={20} />
          <Text fontSize={13}>Wall of love</Text>
        </Flex>

        <Flex w={"100%"} justify="center">
          <Text
            color="#131316"
            fontSize="30px"
            lineHeight="38px"
            fontWeight="600"
            textAlign="center"
          >
            You're in good company
          </Text>
        </Flex>

        <Text
          fontWeight={"400"}
          fontSize="1rem"
          lineHeight="24px"
          color="#5E5F6E"
          maxW="544px"
          textAlign={"center"}
          w={["100%", "50%"]}
          mt="16px"
        >
          See how real businesses use Metricks to achieve remarkable results
          with affiliate marketing.
        </Text>

        <Flex w={"100%"} mt="53px">
          <Box
            display="flex"
            alignItems="center"
            w={{ base: "100%", md: "max" }}
            mx="auto"
          >
            <Box
              w="36px"
              h="36px"
              bg="white"
              display={isDesktop ? "flex" : "none"}
              alignItems="center"
              justifyContent="center"
              boxShadow="0px 2px 4px 0px rgba(18, 55, 105, 0.08)"
              cursor="pointer"
              rounded="full"
              mr="45px"
              onClick={handlePrev}
            >
              <IoChevronBackOutline color="#11244A" size="14px" />
            </Box>

            <Swiper
              effect={"cards"}
              grabCursor={true}
              ref={sliderRef}
              modules={[EffectCards]}
              onSlideChange={(swiper: any) => {
                setactiveSlide(swiper.activeIndex);
                setcurentState("pause");
              }}
              autoHeight
              id="reviews"
              className="mySwiper"
              style={{
                width: "100%",
                maxWidth: isDesktop ? "500px" : "90%",
              }}
            >
              {comments.map((item, i) => (
                <SwiperSlide key={i}>
                  <Flex flexDir={"column"} px="20px" pb="37px" pt="10px">
                    <Box
                      position="relative"
                      w="100%"
                      h={activeSlide === i ? "400px" : "400px"}
                    >
                      {activeSlide === i ? (
                        <>
                          <video
                            id="video"
                            poster={item.cover || undefined}
                            // ref={(el) => (videoRef.current[i] = el)}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "12px",
                            }}
                            src={item.video}
                          ></video>

                          {curentState === "play" ? (
                            <BsFillPauseFill
                              style={{
                                fontSize: "1.5rem",
                                color: "grey",
                                position: "absolute",
                                zIndex: 3,
                                left: "45%",
                                top: "45%",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Image
                              height="40px"
                              width="40px"
                              cursor="pointer"
                              position="absolute"
                              top="45%"
                              left="45%"
                              zIndex={2}
                              src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/84711bbc-c187-4cd1-97bd-37add09b7466.svg"
                              alt="play"
                              onClick={() => {
                                setopenMediaPlayer(true);
                                setcurrentViewedReview(i);
                              }}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Flex mt="25px" maxW="100%">
                      <Text>“{item.review}”</Text>
                    </Flex>

                    <Flex mt="23px" align={"center"} gap={5} maxW="100%">
                      <Avatar
                        size="md"
                        name={item.user}
                        color="white"
                        bg="#7415D5"
                        // src="https://bit.ly/ryan-florence"
                      />
                      <Flex flexDir={"column"}>
                        <Text fontWeight={"500"} fontSize={14}>
                          {item.user}
                        </Text>
                        <Text fontWeight={"300"} fontSize={13}>
                          {item.role}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>

            <Box
              w="36px"
              h="36px"
              bg="white"
              display={isDesktop ? "flex" : "none"}
              alignItems="center"
              justifyContent="center"
              rounded="full"
              boxShadow="0px 2px 4px 0px rgba(18, 55, 105, 0.08)"
              cursor="pointer"
              ml="45px"
              onClick={handleNext}
            >
              <IoChevronForwardOutline color="#11244A" size="14px" />
            </Box>
          </Box>
        </Flex>
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3102c874-c52e-4c87-8e97-0545b80f6693.svg"
          w={"80%"}
          mt={20}
        />
      </Flex>

      {openMediaPlayer ? (
        <MediaPlayer
          activeIndex={currentViewedReview}
          onClose={() => {
            setopenMediaPlayer(false);
          }}
          reviews={comments}
        />
      ) : (
        ""
      )}
    </Flex>
  );
};

export default Comments;
