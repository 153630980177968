import { CgProfile } from "react-icons/cg";
import { RiSearch2Fill } from "react-icons/ri";
import { HiOutlineSpeakerphone } from "react-icons/hi";

import { TbReportAnalytics, TbSwitchVertical } from "react-icons/tb";
import { MdOutlineScale } from "react-icons/md";
export const AdvertiserProps = {
  rowOne: [
    {
      title: "Create an advertiser profile",
      body: "Our onboarding questions are tailored to know you better and give you the best affiliate recommendations to help you promote your products",
      icon: CgProfile,
    },
    {
      title: "Find Affiliates",
      body: "Find and pitch your campaigns to the top-ranking and best-performing affiliates on metricks.",
      icon: RiSearch2Fill,
    },
    {
      title: "Public Campaigns",
      body: "Find public campaigns, see how yours compares, and list your campaigns so new affiliates can find and sign up",
      icon: HiOutlineSpeakerphone,
    },
  ],
  rowTwo: [
    {
      title: "View Reports",
      body: "Never miss an update with real-time reporting that keeps you informed of new events in your organization and/or campaigns.",
      icon: TbReportAnalytics,
    },
    {
      title: "Switch Organizations",
      body: "Find public campaigns, see how yours compares, and list your campaigns so new affiliates can find and sign up",
      icon: TbSwitchVertical,
    },
    {
      title: "Scale",
      body: "Experience no limits - create as many organizations and campaigns as you need. Find and recruit new affiliates, add affiliate managers, and continue to expand your limits. ",
      icon: MdOutlineScale,
    },
  ],
};
export const AffiliateProps = {
  rowOne: [
    {
      title: "Create an affiliate profile",
      body: "Whether you are new to affiliate marketing or not, our onboarding questions are tailored to give you the best campaign recommendations for you to promote.",
      icon: CgProfile,
    },
    {
      title: "Get discovered by brands",
      body: "With full control of your affiliate profile, put your best foot forward and get discovered by leading brands looking to pitch their campaigns to the best in the field.",
      icon: RiSearch2Fill,
    },
    {
      title: "Find and join new campaigns",
      body: "Browse through our offers and find new campaigns to join that are tailored to suit your preference. In just a few clicks, you can start promoting your first campaign on metricks.",
      icon: HiOutlineSpeakerphone,
    },
  ],
  rowTwo: [
    {
      title: "View Reports",
      body: "Never miss an update with real-time reporting that keeps you informed of new events in your organization and or campaigns.",
      icon: TbReportAnalytics,
    },
    {
      title: "Switch Organizations",
      body: "Manage all your organizations with one account on metricks and switch seamlessly between your affiliate and advertiser organizations.",
      icon: TbSwitchVertical,
    },
    {
      title: "Scale",
      body: "Experience no limits - add as many account managers as needed, create new organizations, add your account managers and create your own campaigns.",
      icon: MdOutlineScale,
    },
  ],
};
export const Integrations = [
  {
    title: "BigCommerce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/bc261b09-a625-4fab-92aa-f289f82b38e6.png",
    bgcolor: "#34323e",
    category: "Shopping Carts",
  },
  {
    title: "Salesforce Commerce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/305f8e27-9976-45e7-9de8-bed16595728a.png",
    bgcolor: "#fff",
    category: "Shopping Carts",
  },
  {
    title: "WooCommerce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1d629021-a8a2-46d4-9555-becfc7b26f98.png",
    bgcolor: "#9b5c8f",
    category: "Shopping Carts",
  },
  {
    title: "SquareSpace",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/719ef13a-6bc7-4f3f-9439-683fb70728ee.png",
    bgcolor: "#000",
    category: "Shopping Carts",
  },
  {
    title: "OpenCart",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/fa8af51a-0365-4fc8-91d6-438e7ebec13e.png",
    bgcolor: "#2fbcee",
    category: "Shopping Carts",
  },
  {
    title: "3dcart",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/7c5ec782-7276-44bf-81ce-fceb2f1789b6.png",
    bgcolor: "#027735",
    category: "Shopping Carts",
  },
  {
    title: "Volusion",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/48f2bc6f-06a7-4b3a-b13f-8b7cc8bfde1a.png",
    bgcolor: "#221b43",
    category: "Shopping Carts",
  },
  {
    title: "PrestaShop",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/44d094ee-ae1a-4580-8d4d-a90df5e83bf5.png",
    bgcolor: "#fcfcfd",
    category: "Shopping Carts",
  },
  {
    title: "Ecwid",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/3419693b-ed42-465d-8238-7a9aabeb4b92.png",
    bgcolor: "#f7f7f7",
    category: "Shopping Carts",
  },
  {
    title: "Changebee",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/52d57418-82ed-459f-a6e7-0248cbc2e635.png",
    bgcolor: "#ff6c36",
    category: "Payment Processors",
  },
  {
    title: "Stripe",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/6210b9e4-fab4-421b-a2c6-b96a30caf675.png",
    bgcolor: "#2db2ff",
    category: "Payment Processors",
  },
  {
    title: "Paypal",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a12e3742-8039-4b29-bea5-d64792aac1f3.png",
    bgcolor: "#f6f6f6",
    category: "Payment Processors",
  },
  {
    title: "Yoast",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1765fa7c-8e07-456b-80c0-5daba6c11e4f.png",
    bgcolor: "#f8f8f8",
    category: "WordPress Plugins",
  },
  {
    title: "WooCommerce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1d629021-a8a2-46d4-9555-becfc7b26f98.png",
    bgcolor: "#9b5c8f",
    category: "WordPress Plugins",
  },
  {
    title: "WPForms",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/ac969c7f-fd8a-49f5-962a-37fe69b1d2cd.png",
    bgcolor: "#f8f8f8",
    category: "WordPress Plugins",
  },
  {
    title: "Monsterinsights",
    image: "/assets/png/WordPress/monster.png",
    bgcolor: "#f8f8f8",
    category: "WordPress Plugins",
  },
  {
    title: "Constant Contact",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/5409df07-2045-4730-84f2-74c7f4db6a66.png",
    bgcolor: "#1a56ed",
    category: "WordPress Plugins",
  },
  {
    title: "OptinMonster",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/d14af040-b7f3-407c-9798-4b1497945e62.png",
    bgcolor: "#1888e1",
    category: "WordPress Plugins",
  },
  {
    title: "SeedProd",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/5f049a86-4333-467b-bc7c-0d8e06a1d6fb.png",
    bgcolor: "#f3f1f2",
    category: "WordPress Plugins",
  },
  {
    title: "Memberpress",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a9b514bb-410c-438f-815d-b3f743f3e3cc.png",
    bgcolor: "#c8f5fc",
    category: "WordPress Plugins",
  },
  {
    title: "UpdraftPlus",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/87d766d1-76a1-4513-a205-daa57f28f18d.png",
    bgcolor: "#df6827",
    category: "WordPress Plugins",
  },
  {
    title: "Unbounce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/d1da5eb5-7908-4633-a1b7-290659af03fc.png",
    bgcolor: "#0133ff",
    category: "Lead/Landing Pages",
  },
  {
    title: "Leadpages",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/38865aad-0541-4235-88f5-ef90fcc88749.png",
    bgcolor: "#5f3eff",
    category: "Lead/Landing Pages",
  },
  {
    title: "HubSpot",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/4ee9e71f-2ec4-4130-ba4b-478d84e8b810.png",
    bgcolor: "#f77521",
    category: "Lead/Landing Pages",
  },
  {
    title: "GetResponse",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/d1072af2-c632-4cc7-9608-d7261c939466.png",
    bgcolor: "#00aeef",
    category: "Lead/Landing Pages",
  },
  {
    title: "Clickfunnels",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1002c9b5-016f-427a-a991-8206bc9b9d27.png",
    bgcolor: "#fff",
    category: "Lead/Landing Pages",
  },
  {
    title: "Systeme",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/bf303629-4844-4c13-9675-7a2e097d535b.png",
    bgcolor: "#3b9ff8",
    category: "Lead/Landing Pages",
  },
  {
    title: "Wishpond",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/e063b6b8-9870-4fea-ab79-ceb0d4aec279.png",
    bgcolor: "#f6f6f6",
    category: "Lead/Landing Pages",
  },
  {
    title: "Landingi",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a227baa1-1d15-406e-8053-58e1e64fafe1.png",
    bgcolor: "#f6f6f6",
    category: "Lead/Landing Pages",
  },
  {
    title: "Instapage",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/f0b7166c-eb45-4546-9144-6b9d6c5acee8.png",
    bgcolor: "#0c1929",
    category: "Lead/Landing Pages",
  },
  {
    title: "Pinterest",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/39e96115-948d-456e-bbdb-2b673e732db1.png",
    bgcolor: "#f6f6f6",
    category: "Social Networks",
  },
  {
    title: "Youtube",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/fc67d4a3-1e80-48fd-8aa7-86bf51750364.png",
    bgcolor: "#fefefe",
    category: "Social Networks",
  },
  {
    title: "Facebook",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a8cf280a-1d40-4aaa-a1e4-52f3c20cce03.png",
    bgcolor: "#4967aa",
    category: "Social Networks",
  },
  {
    title: "Twitter",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a5267fc6-76be-4209-8b1f-a840f099dcff.png",
    bgcolor: "#34ccff",
    category: "Social Networks",
  },
  {
    title: "Instagram",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/dbbe4597-e94d-45a3-a360-7f9a15265442.png",
    bgcolor: "#f6f6f6",
    category: "Social Networks",
  },
  {
    title: "Linkedin",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a21f48c4-e4d0-4b22-a92e-4ef5228c357b.png",
    bgcolor: "#0b66c2",
    category: "Social Networks",
  },
  {
    title: "PrestaShop",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/44d094ee-ae1a-4580-8d4d-a90df5e83bf5.png",
    bgcolor: "#fcfcfd",
    category: "Product Level Commissioning",
  },
  {
    title: "WP Simple Pay",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/24ade5d1-7c64-4801-8158-d6d22263111d.png",
    bgcolor: "#428bca",
    category: "Product Level Commissioning",
  },
  {
    title: "BigCommerce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/bc261b09-a625-4fab-92aa-f289f82b38e6.png",
    bgcolor: "#34323e",
    category: "Product Level Commissioning",
  },
  {
    title: "Shopify",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/0976aafc-83c1-43d3-845e-01d915be8fa7.png",
    bgcolor: "#509231",
    category: "Product Level Commissioning",
  },
  {
    title: "WooCommerce Subscriptions",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/5dcdd7f4-3e56-499f-91be-416608347f48.png",
    bgcolor: "#f7f7f7",
    category: "Product Level Commissioning",
  },
  {
    title: "Membermouse",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/4f95312d-2093-43ca-9996-6b020acf7e47.png",
    bgcolor: "#1985e3",
    category: "Product Level Commissioning",
  },
  {
    title: "Wix",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/128c9a15-ae2d-4a29-9d7b-fddc1d028f67.png",
    bgcolor: "#126dff",
    category: "Product Level Commissioning",
  },
  {
    title: "Clickfunnels",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1002c9b5-016f-427a-a991-8206bc9b9d27.png",
    bgcolor: "#fff",
    category: "Product Level Commissioning",
  },
  {
    title: "Magento",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/833b2e09-437d-4c36-8326-74a547c05848.png",
    bgcolor: "#f26321",
    category: "Coupon code commissioning level",
  },
  {
    title: "BigCommerce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/bc261b09-a625-4fab-92aa-f289f82b38e6.png",
    bgcolor: "#34323e",
    category: "Coupon code commissioning level",
  },
  {
    title: "Memberpress",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a9b514bb-410c-438f-815d-b3f743f3e3cc.png",
    bgcolor: "#c8f5fc",
    category: "Coupon code commissioning level",
  },
  {
    title: "WooCommerce Subscriptions",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/5dcdd7f4-3e56-499f-91be-416608347f48.png",
    bgcolor: "#f7f7f7",
    category: "Coupon code commissioning level",
  },
  {
    title: "WP Simple Pay",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/24ade5d1-7c64-4801-8158-d6d22263111d.png",
    bgcolor: "#428bca",
    category: "Coupon code commissioning level",
  },
  {
    title: "Wp Ecommerce",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/4967df2b-17e5-4e24-ba02-6e51f767a3a9.png",
    bgcolor: "#e05c5c",
    category: "Coupon code commissioning level",
  },
  {
    title: "Shopify",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/0976aafc-83c1-43d3-845e-01d915be8fa7.png",
    bgcolor: "#509231",
    category: "Coupon code commissioning level",
  },
  {
    title: "Stripe",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/6210b9e4-fab4-421b-a2c6-b96a30caf675.png",
    bgcolor: "#2db2ff",
    category: "Coupon code commissioning level",
  },
  {
    title: "UpSell Plugin",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/3cd65d81-fb27-4551-b716-75d0cc91c896.png",
    bgcolor: "#000",
    category: "Coupon code commissioning level",
  },
  {
    title: "Recurly",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/d79e0c2b-87ad-4b86-b344-71e833e32a5d.png",
    bgcolor: "#6c2f79",
    category: "Subscription/membership",
  },
  {
    title: "Memberpress",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a9b514bb-410c-438f-815d-b3f743f3e3cc.png",
    bgcolor: "#c8f5fc",
    category: "Subscription/membership",
  },
  {
    title: "MailChimp",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/566436c7-d121-4deb-963c-b520eefd06d6.png",
    bgcolor: "#fee103",
    category: "CRM",
  },
  {
    title: "Jotform",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/ab66877c-ff53-4e6a-a10e-9212d2ec9bd9.png",
    bgcolor: "#091551",
    category: "CRM",
  },
  {
    title: "Constant Contact",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/5409df07-2045-4730-84f2-74c7f4db6a66.png",
    bgcolor: "#1a56ed",
    category: "CRM",
  },
  {
    title: "ActiveCampaign",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/3fbbc286-064e-43f1-b77a-1368eacd9c6f.png",
    bgcolor: "#004cff",
    category: "CRM",
  },
  {
    title: "ConvertKit",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/1638e62a-10b1-477d-9f6e-eef93937bc83.png",
    bgcolor: "#21304f",
    category: "CRM",
  },
  {
    title: "Zoho CRM",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/49eb94bd-ed49-45f3-89b9-a8916ef1a527.png",
    bgcolor: "#f3f1f2",
    category: "CRM",
  },
  {
    title: "PipeDrive",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/dff8b73c-18ae-49f6-a65b-7d587e42492a.png",
    bgcolor: "#25292c",
    category: "CRM",
  },
  {
    title: "Memberpress",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/a9b514bb-410c-438f-815d-b3f743f3e3cc.png",
    bgcolor: "#c8f5fc",
    category: "CRM",
  },
  {
    title: "Keap",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/179a3ec6-d464-41f9-9133-ddf05732cd37.png",
    bgcolor: "#37a735",
    category: "CRM",
  },

  {
    title: "Zapier",
    image:
      "https://metricksassets.s3.us-east-2.amazonaws.com/development/default/436312af-15b9-419b-96f9-8a7f66587995.png",
    bgcolor: "#fe4a01",
    category: "Webhooks",
  },
];
export const IntegrationsCategories = [
  "All Products",
  "Shopping Carts",
  "Payment Processors",
  "WordPress Plugins",
  "Lead/Landing Pages",
  "Social Networks",
  "Product Level Commissioning",
  "Coupon code commissioning level",
  "Subscription/membership",
  "CRM",
  "Webhooks",
];
