import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { GoTriangleRight } from "react-icons/go";

const Hero = () => {
  return (
    <Box pt={{ base: "53px", lg: "83px" }} bgColor="#F6F9FC" h="max">
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        justifyContent={{ lg: "space-between" }}
        maxW={{ lg: "90%" }}
        mx="auto"
        position="relative"
        zIndex="1"
        px={5}
      >
        <Box mr={{ lg: "20px" }} maxW={{ lg: "55%" }}>
          <Text
            color="#231645"
            fontSize="0.75rem"
            fontWeight="400"
            lineHeight="20px"
            px="14px"
            py="10px"
            rounded="999px"
            shadow="base"
            w="max"
            bgColor="white"
          >
            E-commerce Solution
          </Text>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Text
              mt={{ base: "10px", lg: "16px" }}
              mb={{ base: "16px", lg: "24px" }}
              fontSize={{ base: "2.25rem", lg: "4rem" }}
              fontWeight="700"
              lineHeight={{ base: "44px", lg: "72px" }}
              color="#131316"
            >
              Drive More Sales and Amplify Your Reach with Affiliate Marketing
            </Text>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 200 }}
            transition={{ delay: 0.2 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Text
              fontSize={{ base: "0.875rem", lg: "1rem" }}
              lineHeight={{ base: "22px", lg: "24px" }}
              color="#5E5F6E"
              fontWeight="400"
            >
              Supercharge your e-commerce business with a tool that’s designed
              to grow with your business.
            </Text>
          </motion.div>

          <Flex
            alignItems="center"
            mt={{ base: "60px", lg: "48px" }}
            maxW={{ base: "300px", lg: "none" }}
            flexDir={{ base: "column", lg: "row" }}
            mx={{ base: "auto", lg: "0px" }}
          >
            <Button
              rightIcon={<GoTriangleRight style={{ opacity: "0.8" }} />}
              color={"#fff"}
              borderRadius={10}
              fontWeight="500"
              w={{ base: "100%", lg: "max" }}
              onClick={() =>
                window.open("https://cal.com/metricks/15min", "_blank")
              }
              _hover={{
                color: "#fff",
              }}
              mr={{ lg: "16px" }}
              mb={{ base: "16px", lg: "0px" }}
              zIndex={1}
              bgGradient="linear(rgba(7, 174, 7, 0.6) 40%, rgba(7, 174, 7, 1) 60%)"
            >
              Book a Demo
            </Button>

            {/* <Button
              rightIcon={<GoTriangleRight style={{ color: "#858585" }} />}
              color={"#272727"}
              borderRadius={10}
              bgColor="#fff"
              fontWeight="500"
              w={{ base: "100%", lg: "max" }}
              onClick={() =>
                window.open("https://app.metricks.io/signup", "_blank")
              }
              zIndex={1}
              _hover={{ color: "#c4c4c4" }}
              shadow={"base"}
            >
              Launch Your Growth Engine Now
            </Button> */}
          </Flex>
        </Box>

        <Image
          src="/assets/png/ecommerce-hero-desktop.png"
          alt="hero desktop"
          maxW={{ base: "80%", lg: "45%" }}
          w={{ base: "80%", lg: "45%" }}
          mt={{ base: "65px", lg: "0px" }}
          ml={{ base: "auto", lg: "0px" }}
          transform="scale(1.15)"
          objectFit="contain"
        />

        <Image
          src="/assets/png/ecommerce-hero-mobile.png"
          alt="hero desktop"
          position="absolute"
          right={{ base: "20px", lg: "0px" }}
          bottom={{ base: "-25%", lg: "-45%" }}
          transform="scale(1.5)"
          w={{ base: "175px", lg: "250px" }}
          objectFit="contain"
          h="auto"
        />
      </Flex>

      <Image
        src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/74a5ccc8-541e-4e2e-ac41-b61c79cb5ef7.svg"
        alt="border"
        w="full"
        mt={{ base: "135px", lg: "146px" }}
      />
    </Box>
  );
};

export default Hero;
