import { Button, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { GiBullseye } from "react-icons/gi";
import { GoTriangleRight } from "react-icons/go";
import { LiaBullseyeSolid } from "react-icons/lia";
import { HiArrowUp } from "react-icons/hi";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import Lottie from "lottie-react";
import graph from "./graph.json";
import waves from "./waves.json";
const Penetrate = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  return (
    <Flex justify={"center"} bgColor={"#131515"}>
      <Flex
        align="center"
        justify={"center"}
        w={{ base: "90%", lg: "80%" }}
        py={20}
        flexDir={"column"}
        gap={5}
        overflow={"hidden"}
      >
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          transition={{ duration: 0.6 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          style={{ width: isDesktop ? "70%" : "100%" }}
        >
          <Text
            fontWeight={"500"}
            fontSize={35}
            color={"white"}
            textAlign={"center"}
          >
            Penetrate into any market and grow through the power of affiliate
            marketing.
          </Text>
          <Text
            fontWeight={"300"}
            fontSize={13}
            textAlign={"center"}
            w={["100%", "100%"]}
            color={"#c4c4c4"}
          >
            Technology knows no limits, and neither should your brand. Metricks
            empowers you to conquer the world of affiliate marketing with a
            powerful suite of tools designed to simplify, optimize, and
            skyrocket your program's success.
          </Text>
        </motion.div>
        <Flex
          w={"100%"}
          gap={1}
          mt={10}
          flexDir={!isDesktop ? "column" : "row"}
        >
          <Flex flex={1} flexDir={"column"} gap={1}>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 0.2, duration: 0.6 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                //   h={"420px"}
                bgColor={"#F7F7F8"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                px={10}
                position={"relative"}
                overflow={"hidden"}
                //   _hover={{
                //     shadow: "2xl",
                //     bgColor: "#fff",
                //   }}
                background={`url(/assets/svg/div.group.svg)`}
                backgroundSize="cover"
                backgroundPosition="center"
              >
                <motion.div
                  initial={{ opacity: 0, y: 200 }}
                  transition={{ delay: 0.4, duration: 2, repeat: Infinity }}
                  whileInView={{ opacity: 1, y: 0 }}

                  // viewport={{ once: true }}
                >
                  <Image
                    src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/7054e45f-6c0f-439d-9a87-fe27243c7439.svg"
                    h={"200px"}
                    // position={"absolute"}
                    mt={5}
                  />
                </motion.div>

                <Flex w={"100%"}>
                  <Text fontWeight={"500"} color={"white"}>
                    Launch & Track Campaigns with Ease
                  </Text>
                </Flex>
                <Text fontWeight={"200"} fontSize={14} color={"#c4c4c4"}>
                  Create high-performing campaigns and get real time insights on
                  their performance across your global network
                </Text>
                <Flex w={"100%"} mb={10}></Flex>
              </Flex>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 0.6, duration: 0.6 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#1E2223"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                px={10}
                position={"relative"}
                overflow={"hidden"}
                _hover={{
                  shadow: "2xl",

                  // bgColor: "#fff",
                }}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/9c39422a-cca8-4315-82b0-1c1d26c7ca18.svg"
                  w="100%"
                  mt={20}
                  // position={"absolute"}
                />
                <Flex w={"100%"}>
                  <Text fontWeight={"500"} color={"white"}>
                    Build Your Advocate Army - Amplify Your Reach Globally
                  </Text>
                </Flex>
                <Text fontWeight={"200"} fontSize={14} color={"#c4c4c4"}>
                  Discover and connect with a vast pool of worldwide affiliates
                  ready to cultivate strong partnerships and amplify your
                  message across new markets
                </Text>
                <Flex w={"100%"} mb={10}></Flex>
              </Flex>
            </motion.div>
          </Flex>
          <Flex flex={1} align={"center"}>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 0.8, duration: 0.6 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#1E2223"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                px={5}
                overflow={"hidden"}
              >
                <Flex w={"100%"} mt={10} fontSize={11} flexDir={"column"}>
                  <Text fontWeight={"300"} color={"#c4c4c4"}>
                    Sales
                  </Text>
                  <Text fontWeight={"500"} fontSize={35} color={"white"}>
                    $
                    <CountUp
                      end={54847.3}
                      duration={6}
                      start={30000}
                      style={{ color: "#fff" }}
                    />
                    .30
                  </Text>
                  <Flex gap={2} align={"center"}>
                    <HiArrowUp color="#1CEC87" />
                    <Text color="#1CEC87">
                      <CountUp end={4} duration={6} start={0} />
                      .24%
                    </Text>
                    <Text color="#1CEC87">
                      $
                      <CountUp end={1023} duration={6} start={33} />
                      .94
                    </Text>
                    <Text color="#c4c4c4">Today</Text>
                  </Flex>
                </Flex>

                <Flex h={"30%"} w={"60%"}>
                  <Lottie animationData={graph} loop={true} />
                </Flex>

                <Flex w={"100%"}>
                  <Text fontWeight={"500"} color={"white"}>
                    Data-Driven Decisions for Maximum ROI
                  </Text>
                </Flex>
                <Text fontWeight={"200"} fontSize={14} color={"#c4c4c4"}>
                  Identify top-performing affiliates across all regions and
                  optimize your campaigns for maximum return on investment by
                  gaining in-depth insights of their performances.
                </Text>
                <Flex w={"100%"} mb={10}></Flex>
              </Flex>
            </motion.div>
          </Flex>
          <Flex flex={1} flexDir={"column"} gap={1}>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 1, duration: 0.6 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#1E2223"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                position={"relative"}
                overflow={"hidden"}
                //   _hover={{
                //     shadow: "2xl",
                //     bgColor: "#fff",
                //   }}
              >
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/09f38b1b-bd8a-4fae-b9f6-51e2d4219e75.svg"
                  w="100%"
                  position={"relative"}
                />
                {/* <motion.img
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3a9a566c-f0c8-4b94-ba72-850307b24d2f.svg"
                  alt="Your Image"
                  style={{
                    width: "25%",
                    position: "absolute",
                    top: "68px",
                    left: "38%",
                  }}
                  animate={{ rotate: 720 }} // Animate rotation to 360 degrees
                  transition={{ duration: 4, repeat: Infinity }} // Repeat animation infinitely
                /> */}

                <Flex w={"100%"} px={10}>
                  <Text fontWeight={"500"} color={"white"}>
                    Build Your Advocate Army - Amplify Your Reach Globally
                  </Text>
                </Flex>
                <Text
                  fontWeight={"200"}
                  fontSize={14}
                  color={"#c4c4c4"}
                  px={10}
                >
                  Discover and connect with a vast pool of worldwide affiliates
                  ready to cultivate strong partnerships and amplify your
                  message across new markets
                </Text>
                <Flex w={"100%"} mb={10}>
                  {/* <Button
                  rightIcon={<GoTriangleRight />}
                  color={"#000"}
                  borderRadius={10}
                  fontWeight={"400"}
                  shadow={"base"}
                  _hover={{
                    color: "#c4c4c4",
                    //   bgColor: "#c4c4c4",
                  }}
                  bgColor={"#fff"}
                >
                  View all
                </Button> */}
                </Flex>
              </Flex>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              transition={{ delay: 1.2, duration: 0.6 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Flex
                w={"100%"}
                bgColor={"#1E2223"}
                // justify={"center"}
                align={"center"}
                flexDir={"column"}
                gap={5}
                position={"relative"}
                overflow={"hidden"}
                //   _hover={{
                //     shadow: "2xl",
                //     bgColor: "#fff",
                //   }}
              >
                {/* <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/3e52ca03-ace2-43d0-a127-5d6a46473bfc.svg"
                  w="100%"
                  // position={"absolute"}
                /> */}
                <Flex
                  bgColor={"#2C3031"}
                  h={["200px", "200px"]}
                  w={"100%"}
                  justify={"center"}
                >
                  <Flex w={"60%"}>
                    <Lottie animationData={waves} loop={true} />
                    <Lottie animationData={waves} loop={true} />
                    <Lottie animationData={waves} loop={true} />
                  </Flex>
                </Flex>

                <Flex w={"100%"} px={10}>
                  <Text fontWeight={"500"} color={"white"}>
                    Build Your Advocate Army - Amplify Your Reach Globally
                  </Text>
                </Flex>
                <Text
                  fontWeight={"200"}
                  fontSize={14}
                  color={"#c4c4c4"}
                  px={10}
                >
                  Discover and connect with a vast pool of worldwide affiliates
                  ready to cultivate strong partnerships and amplify your
                  message across new markets
                </Text>
                <Flex w={"100%"} mb={10}>
                  {/* <Button
                  rightIcon={<GoTriangleRight />}
                  color={"#000"}
                  borderRadius={10}
                  fontWeight={"400"}
                  shadow={"base"}
                  _hover={{
                    color: "#c4c4c4",
                    //   bgColor: "#c4c4c4",
                  }}
                  bgColor={"#fff"}
                >
                  View all
                </Button> */}
                </Flex>
              </Flex>
            </motion.div>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Penetrate;
