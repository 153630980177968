import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import FeatureCol1 from "./FeatureCol1";
import FeatureCol2 from "./FeatureCol2";
import FeatureCol3 from "./FeatureCol3";

const Features = () => {
  return (
    <Box bgColor="#FAFAFB" pt={{ base: "70px", lg: "92px" }}>
      <Box mx="auto" maxW={{ md: "90%" }} px={5}>
        <Text
          lineHeight={{ base: "32px", lg: "40px" }}
          fontSize={{ base: "1.5rem", lg: "30px" }}
          fontWeight="600"
          color="#131316"
        >
          Power any affiliate model
        </Text>

        <Flex
          mt={{ base: "27px", lg: "34px" }}
          columnGap={{ lg: "57px" }}
          flexDir={{ base: "column", lg: "row" }}
        >
          <FeatureCol1 />
          <FeatureCol2 />
          <FeatureCol3 />
        </Flex>
      </Box>

      <Image
        src="/assets/svg/saas-section-border-2.svg"
        alt="border"
        w="full"
        mt={{ base: "105px", lg: "60px" }}
      />
    </Box>
  );
};

export default Features;
