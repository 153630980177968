import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link, NavLink, Navigate } from "react-router-dom";
import { useContext, useState } from "react";
import { NavContext } from "../../context/NavContext";
import { IoPricetagOutline } from "react-icons/io5";

import { CgNotes } from "react-icons/cg";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineCampaign } from "react-icons/md";
import { motion } from "framer-motion";
import { GoTriangleRight } from "react-icons/go";

const Insights = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { isAdvertiser, setisAdvertiser } = useContext(NavContext);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const [selected, setSelected] = useState("reports");
  return (
    <Flex
      // background={
      //   isDesktop
      //     ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
      //     : "#f8f4fd"
      // }
      bgColor={"#F2F2F2"}
      justify={"center"}
      position={"relative"}
      overflow={"hidden"}
    >
      <Flex
        // borderBottom={1}
        borderStyle={"solid"}
        // borderColor={useColorModeValue("gray.200", "gray.900")}
        // align={"center"}
        w={{ base: "90%", lg: "80%" }}
        py={10}
        flexDir={!isDesktop ? "column-reverse" : "row"}
        // px={10}
      >
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          transition={{ duration: 0.8 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          style={{ flex: 1 }}
        >
          {selected == "campaigns" ? (
            <Flex flex={1} flexDir={"column"} gap={3} pr={5} pt={10}>
              <Flex
                w={"50px"}
                h={"50px"}
                justify={"center"}
                align={"center"}
                bgColor={"#fff"}
                borderRadius={10}
              >
                <MdOutlineCampaign width={"50px"} height={"50px"} />
              </Flex>
              <Text fontWeight={"300"}>CAMPAIGNS</Text>
              <Text fontWeight={"400"} fontSize={30}>
                Craft High-Converting Affiliate Programs with Ease
              </Text>
              <Text fontWeight={"300"} fontSize={17}>
                Launch sales driving campaigns and reward your affiliate network
                conveniently on Metricks.
              </Text>
              <Flex flexDir={"column"} mt={5} gap={5}>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Effortless Campaign Creation
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Tailored Settings
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Flexible Commission Structures
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Streamlined Payout Management
                  </Text>
                </Flex>
                <Flex>
                  <Link to="https://cal.com/metricks/15min">
                    <Button
                      rightIcon={<GoTriangleRight />}
                      color={"#fff"}
                      borderRadius={10}
                      fontWeight={"500"}
                      boxShadow="custom"
                      _hover={{
                        color: "#fff",
                        //   bgColor: "#c4c4c4",
                      }}
                      bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
                    >
                      Book a Demo
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          ) : selected == "reports" ? (
            <Flex w={"100%"} flexDir={"column"} gap={3} pr={5} pt={10}>
              <Flex
                w={"50px"}
                h={"50px"}
                justify={"center"}
                align={"center"}
                bgColor={"#fff"}
                borderRadius={10}
              >
                <CgNotes width={"50px"} height={"50px"} />
              </Flex>
              <Text fontWeight={"300"}>REPORTS</Text>
              <Text fontWeight={"400"} fontSize={30}>
                Gain Actionable Insights and Make Informed Decisions
              </Text>
              <Text fontWeight={"300"} fontSize={17}>
                See what drives results, monitor the activity and effectiveness
                of your entire affiliate network in one place.
              </Text>
              <Flex flexDir={"column"} mt={5} gap={5}>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Track Affiliate Performance
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Identify Top Performers
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Gain Financial Clarity
                  </Text>
                </Flex>
                <Flex
                  w={"100%"}
                  justify={isDesktop ? "flex-start" : "center"}
                  align={"center"}
                >
                  <Link to="https://cal.com/metricks/15min">
                    <Button
                      rightIcon={<GoTriangleRight />}
                      color={"#fff"}
                      borderRadius={10}
                      fontWeight={"500"}
                      // boxShadow="custom"
                      _hover={{
                        color: "#fff",
                        //   bgColor: "#c4c4c4",
                      }}
                      bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
                      w={isDesktop ? "" : "90vw"}
                    >
                      Book a Demo
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Flex flex={1} flexDir={"column"} gap={3} pr={5} pt={10}>
              <Flex
                w={"50px"}
                h={"50px"}
                justify={"center"}
                align={"center"}
                bgColor={"#fff"}
                borderRadius={10}
              >
                <IoPricetagOutline width={"50px"} height={"50px"} />
              </Flex>
              <Text fontWeight={"300"}>EXPLORE</Text>
              <Text fontWeight={"400"} fontSize={30}>
                Expand Your Reach and Discover High-Potential Affiliates
              </Text>
              <Text fontWeight={"300"} fontSize={17}>
                Find affiliates tailored and ready to help your business hit the
                global market
              </Text>
              <Flex flexDir={"column"} mt={5} gap={5}>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Vast Affiliate Network
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Targeted Search Filters
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Effortless Outreach
                  </Text>
                </Flex>
                <Flex gap={3} align={"center"}>
                  <IoIosCheckmarkCircleOutline color="#5676FA" fontSize={27} />
                  <Text fontWeight={"400"} fontSize={17}>
                    Scale with Confidence
                  </Text>
                </Flex>
                <Flex>
                  <Link to="https://cal.com/metricks/15min">
                    <Button
                      rightIcon={<GoTriangleRight />}
                      color={"#fff"}
                      borderRadius={10}
                      fontWeight={"500"}
                      boxShadow="custom"
                      _hover={{
                        color: "#fff",
                        //   bgColor: "#c4c4c4",
                      }}
                      bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
                    >
                      Book a Demo
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          )}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          transition={{ duration: 0.8 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          style={{ flex: 2 }}
        >
          <Flex
            flex={1}
            borderLeftColor={"#D6E0E3E3"}
            borderRightColor={"#D6E0E3E3"}
            borderLeftWidth={1}
            borderRightWidth={1}
            flexDir={"column"}
          >
            <Flex
              gap={5}
              py={3}
              borderTopColor={"#D6E0E3E3"}
              borderBottomColor={"#D6E0E3E3"}
              borderTopWidth={1}
              borderBottomWidth={1}
              w={"100%"}
            >
              <Button
                // bgColor={"#333337"}
                color={selected == "reports" ? "#c4c4c4" : "#000"}
                borderRadius={50}
                fontWeight={"500"}
                _hover={{
                  color: "#c4c4c4",
                  bgColor: "#c4c4c4",
                }}
                bgGradient={
                  selected == "reports"
                    ? "linear(#48484C 40%, #232328 60%)"
                    : "linear(#fff 40%, #E7ECFF 60%)"
                }
                shadow={"base"}
                onClick={() => {
                  setSelected("reports");
                }}
              >
                Reports
              </Button>
              <Button
                // bgColor={"#333337"}
                color={selected == "campaigns" ? "#c4c4c4" : "#000"}
                borderRadius={50}
                fontWeight={"500"}
                _hover={{
                  color: "#c4c4c4",
                  bgColor: "#c4c4c4",
                }}
                bgGradient={
                  selected == "campaigns"
                    ? "linear(#48484C 40%, #232328 60%)"
                    : "linear(#fff 40%, #E7ECFF 60%)"
                }
                shadow={"base"}
                onClick={() => {
                  setSelected("campaigns");
                }}
              >
                Campaigns
              </Button>
              <Button
                // bgColor={"#333337"}
                color={selected == "explore" ? "#c4c4c4" : "#000"}
                borderRadius={50}
                fontWeight={"500"}
                _hover={{
                  color: "#c4c4c4",
                  bgColor: "#c4c4c4",
                }}
                bgGradient={
                  selected == "explore"
                    ? "linear(#48484C 40%, #232328 60%)"
                    : "linear(#fff 40%, #E7ECFF 60%)"
                }
                shadow={"base"}
                onClick={() => {
                  setSelected("explore");
                }}
              >
                Explore
              </Button>
            </Flex>
            <Flex w={"100%"} pl={3} pt={3}>
              {selected == "reports" ? (
                <Image src="/assets/svg/dashboard.svg" w="100%" />
              ) : selected == "campaigns" ? (
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/de5e53fb-1600-4ca6-8de2-91eec21835a4.svg"
                  w="100%"
                />
              ) : (
                <Image
                  src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/dd9123f9-9fee-4c01-88b4-bad0eb485df1.svg"
                  w="100%"
                />
              )}
            </Flex>
          </Flex>
        </motion.div>
      </Flex>
    </Flex>
  );
};

export default Insights;
