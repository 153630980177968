import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import { GoTriangleRight } from "react-icons/go";

const FeatureCol3 = () => {
  return (
    <Box
      bgGradient="linear(to bottom right, #31BB31 35%, #208320 65%)"
      px="27px"
      py="22px"
      shadow="2xl"
      borderRadius="8px"
      w={{ base: "85%", lg: "33%" }}
      maxW={{ lg: "500px" }}
      mx={{ base: "auto", lg: "0px" }}
      mt={{ base: "93px", lg: "0px" }}
      h="max"
    >
      <Text color="#FFFF" fontWeight="600" fontSize="1.5rem" lineHeight="32px">
        Ready to unlock the full potential of e-commerce affiliate marketing?
      </Text>

      <Text
        mt="16px"
        color="rgba(255, 255, 255, 0.8)"
        fontWeight="400"
        fontSize="0.875rem"
        lineHeight="24px"
      >
        Launch Your Affiliate Program in 5 minutes, Track, Optimize, and Measure
        the Performance of your Affiliate Programs with Ease.
      </Text>

      <Box mt="22px">
        <Button
          rightIcon={
            <GoTriangleRight style={{ opacity: "0.8", fontSize: "1.25rem" }} />
          }
          color={"#fff"}
          borderRadius={10}
          fontWeight="500"
          onClick={() =>
            window.open("https://cal.com/metricks/15min", "_blank")
          }
          _hover={{ color: "#fff" }}
          w="full"
          mr="16px"
          fontSize="0.875rem"
          zIndex={1}
          bgGradient="linear(rgba(33, 33, 38, 0.75) 40%, rgba(33, 33, 38, 1) 60%)"
        >
          Book a Demo
        </Button>

        {/* <Button
          rightIcon={
            <GoTriangleRight
              style={{ color: "#858585", fontSize: "1.25rem" }}
            />
          }
          color={"#272727"}
          borderRadius={10}
          bgColor="#fff"
          fontWeight="500"
          mt="21px"
          onClick={() =>
            window.open("https://app.metricks.io/signup", "_blank")
          }
          zIndex={1}
          fontSize="0.875rem"
          _hover={{ color: "#c4c4c4" }}
          w="full"
          shadow={"base"}
        >
          Launch Your Growth Engine Now
        </Button> */}
      </Box>
    </Box>
  );
};

export default FeatureCol3;
