import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  ArrowForwardIcon,
  ChevronRightIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import { BsFillPinAngleFill } from "react-icons/bs";
import { NavContext } from "../../context/NavContext";
import { useLocation } from "react-router-dom";
const AffEcwid = () => {
  const { isAdvertiser, setisAdvertiser } = useContext(NavContext);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
      >
        <Flex
          align="center"
          justify={{ base: "center", md: "space-between", xl: "space-between" }}
          direction={{ base: "column-reverse", md: "row" }}
          w={{ base: "90%", lg: "80%" }}
          //   backgroundColor={"red"}
          minH="60vh"
        >
          <Stack
            spacing={4}
            w={{ base: "100%", md: "40%" }}
            align={["center", "center", "flex-start", "flex-start"]}
            ml={isDesktop ? "20px" : "0px"}
            //   backgroundColor={"red"}
          >
            <Heading
              as="h1"
              size="2xl"
              fontWeight="700"
              color="primary.800"
              textAlign={["center", "center", "left", "left"]}
            >
              Best Affiliate Software For Ecwid
            </Heading>

            <Text
              as="h2"
              size="md"
              color="primary.800"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["center", "center", "left", "left"]}
              //   fontFamily={"heading"}
            >
              Launch your Ecwid affiliate marketing campaign today! With
              metricks, an innovative affiliate marketing solution for Ecwid
              stores, you can boost your word-of-mouth marketing.
            </Text>

            <Link to="https://app.metricks.io/login">
              <Button
                color="#fff"
                backgroundColor="#7211D4"
                borderRadius={50}
                py="7"
                px="6"
                lineHeight="1"
                size="md"
                rightIcon={<ChevronRightIcon />}
              >
                Start Now
              </Button>
            </Link>
          </Stack>
          <Flex
            w={{ base: "100%", sm: "60%", md: "50%" }}
            mb={{ base: 12, md: 0 }}
            //   backgroundColor={"red"}
            justify={"flex-end"}
          >
            <Image
              src="https://metricksassets.s3.us-east-2.amazonaws.com/development/default/26f8997f-c8df-4646-aae6-ed387625b70c.png"
              rounded="1rem"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex align={"center"} justify={"center"} mb={30} py={20}>
        <Flex align={"center"} justify={"center"} direction={"column"} gap={7}>
          <Heading
            as="h1"
            size="xl"
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center"]}
            w={"80%"}
          >
            With metricks, an innovative affiliate marketing solution for Ecwid
            stores, you can boost your word-of-mouth marketing
          </Heading>

          <Text
            as="h2"
            size="md"
            color="primary.800"
            opacity="0.8"
            fontWeight="400"
            lineHeight={1.5}
            textAlign={["left", "left", "center", "center"]}
            w={"60%"}
          >
            We aim at increasing sales and expanding your Ecwid store,
            monitoring, and enhancing affiliate marketing and referral programs.
          </Text>

          <Box
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
            shadow={"md"}
            width={"80%"}
            position={"relative"}
            p={10}
          >
            <Circle
              size="60px"
              bg="white"
              color="#7139d4"
              position={"absolute"}
              top={"-30px"}
              background="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
              _hover={{
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 12px 20px, rgba(0, 0, 0, 0.2) 0px 3px 6px",
                transform: "translateY(-2px)",
              }}
            >
              <BsFillPinAngleFill size={"25px"} />
            </Circle>
            <Text
              as="h2"
              size="md"
              color="#27374C"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["left", "left"]}
            >
              Our straightforward, yet meticulously chosen features seamlessly
              transform your existing customers and store visitors into
              affiliates, generating new purchases for you.
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Flex align={"center"} justify={"center"} direction={"column"} gap={7}>
        <Flex
          borderTopWidth={0.5}
          borderTopColor={"#c4c4c4"}
          direction={"column"}
          width={"80%"}
          position={"relative"}
          p={10}
          gap={3}
        >
          <Heading size={"md"}>Why Choose metricks?</Heading>
          <Text color="primary.800" size={"md"} opacity="0.8">
            Outstanding Customer Service and One-Click Launch.
          </Text>
          <>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                No coding knowledge is required.
              </Text>
            </Flex>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Everyone can effortlessly switch over from your current
                affiliate platform so your Ecwid store may expand as soon as
                possible.
              </Text>
            </Flex>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                You can always count on our customer success team to guide you
                through the onboarding process and answer any questions you may
                have.
              </Text>
            </Flex>
          </>
        </Flex>
        <Flex
          borderTopWidth={0.5}
          borderTopColor={"#c4c4c4"}
          direction={"column"}
          width={"80%"}
          position={"relative"}
          p={10}
          gap={3}
        >
          <Heading size={"md"}>Why Choose metricks?</Heading>

          <>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Scalable for novices and professionals
              </Text>
            </Flex>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Here are numerous built-in, customizable, and ready-to-use
                marketing solutions.
              </Text>
            </Flex>
            <Flex gap={3} align={"center"}>
              <Circle backgroundColor={"#7211D4"} size={3}></Circle>
              <Text color="primary.800" size={"md"} opacity="0.8">
                Support for sophisticated commissioning features built-in.
                Per-product commissions and commissions for coupon codes.
              </Text>
            </Flex>
          </>
        </Flex>
      </Flex>

      <Flex
        style={{
          background:
            "conic-gradient(from 5.87deg at 58.99% 0%, rgba(114, 17, 212, 0) -118.12deg, rgba(114, 17, 212, 0.56) 20.77deg, rgba(114, 17, 212, 0) 241.88deg, rgba(114, 17, 212, 0.56) 380.77deg)",
        }}
        justify={"center"}
        width={"100%"}
      >
        <Flex
          w={"80%"}
          align={"center"}
          justify={{ md: "center", base: "center" }}
        >
          <Flex
            align="center"
            // direction={{ base: "column-reverse", md: "row" }}
            w={{ base: "100%", md: "50%" }}
            py={20}
          >
            <Flex
              //   divider={<StackDivider borderColor='gray.200' />}
              gap={10}
              align={{ base: "center", md: "center" }}
              // justify={{ md: "flex-start", base: "center" }}
              // backgroundColor={"red"}
              direction={"column"}
            >
              <Heading textAlign={{ base: "center", md: "center" }}>
                Take your affiliate marketing to the next level.
              </Heading>

              <Flex alignItems={"flex-start"} gap={5}>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#000"
                    backgroundColor="#fff"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Sign up
                  </Button>
                </Link>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#fff"
                    backgroundColor="#7211D4"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Talk to a Human
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default AffEcwid;
