import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { GoTriangleRight } from "react-icons/go";
import { motion } from "framer-motion";
import { HiArrowUp } from "react-icons/hi";

const StillQuestions = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");

  return (
    <Box
      mx="auto"
      maxW="95%"
      bgColor="#2C2D2D"
      rounded="56px"
      position="relative"
      h="max"
      overflow="hidden"
      pt="44px"
      mt="15px"
      pb="127px"
      mb="127px"
    >
      {isDesktop ? (
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/4d3a7fc9-b56d-49b9-9f60-6ebdeb99517c.svg"
          alt="stroke left"
          w={"48%"}
          top="60px"
          position="absolute"
          left="-10px"
        />
      ) : (
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/b5e3c9cf-c140-43f4-85b8-63905dee6d24.svg"
          alt="stroke left"
          w={"48%"}
          top="60px"
          position="absolute"
          left="-10px"
        />
      )}

      {isDesktop ? (
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/ff98ed80-f242-47f3-85bf-252bf5f93ad7.svg"
          alt="stroke right"
          w={"48%"}
          top="60px"
          position="absolute"
          right="-10px"
        />
      ) : (
        <Image
          src="https://metricksassets.s3.us-east-2.amazonaws.com/production/default/ed066c69-ae0b-42d5-9bef-19aeb9bcb7da.svg"
          alt="stroke right"
          w={"48%"}
          top="60px"
          position="absolute"
          right="-10px"
        />
      )}

      <Flex
        mx="auto"
        flexDir="column"
        alignItems="center"
        position="relative"
        zIndex="2"
        paddingX={"16px"}
      >
        <Flex
          align={"center"}
          gap={3}
          px={3}
          py={1}
          borderWidth={1}
          borderColor={"#39463A"}
          shadow={"base"}
          bg="#2C2D2D"
          boxShadow={"0 0 7px rgba(0, 128, 0, 0.5)"}
          borderRadius={50}
        >
          <HiArrowUp color="rgba(0, 128, 0)" />
          <Text fontSize={15} fontWeight={"600"} color={"rgba(0, 128, 0)"}>
            3.24%
          </Text>
        </Flex>

        <Flex w={"100%"} justify={"center"} align={"center"}>
          <Text
            fontWeight={"600"}
            fontSize={{ base: "1.5rem", lg: "30px" }}
            lineHeight={{ base: "32px", lg: "40px" }}
            color={"white"}
            mt="56px"
            textAlign="center"
          >
            Still got questions? See Metricks in Action
          </Text>
        </Flex>

        <motion.div
          initial={{ opacity: 0, y: 200 }}
          transition={{ delay: 0.25 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <Text
            fontWeight={"400"}
            fontSize="1rem"
            lineHeight="24px"
            textAlign="center"
            color={"#9394A1"}
            mt="24px"
            maxW="405px"
          >
            Launch Your Affiliate Program in 5 minutes, Track, Optimize, and
            Measure the Performance of your Affiliate Programs with Ease.
          </Text>
        </motion.div>

        <Flex
          maxW={{ base: "85%", lg: "none" }}
          mx={{ base: "auto", lg: "0px" }}
          alignItems="center"
          flexDir={{ base: "column", lg: "row" }}
          mt="48px"
        >
          <Button
            rightIcon={<GoTriangleRight style={{ opacity: "0.8" }} />}
            color={"#fff"}
            borderRadius={10}
            w={isDesktop ? "" : "100%"}
            fontWeight="500"
            onClick={() =>
              window.open("https://cal.com/metricks/15min", "_blank")
            }
            _hover={{
              color: "#fff",
            }}
            mr={{ lg: "16px" }}
            mb={{ base: "16px", lg: "0px" }}
            zIndex={1}
            bgGradient="linear(rgba(7, 174, 7, 0.6) 40%, rgba(7, 174, 7, 1) 60%)"
          >
            Book a Demo
          </Button>

          {/* <Button
            rightIcon={<GoTriangleRight style={{ color: "#858585" }} />}
            color={"#272727"}
            borderRadius={10}
            bgColor="#fff"
            fontWeight="500"
            w={{ base: "100%", lg: "max" }}
            onClick={() =>
              window.open("https://app.metricks.io/signup", "_blank")
            }
            zIndex={1}
            _hover={{ color: "#c4c4c4" }}
            shadow={"base"}
          >
            Launch Your Growth Engine Now
          </Button> */}
        </Flex>
      </Flex>

      <Box
        bg="rgba(255, 255, 255, 0.1)"
        h="700px"
        w="15%"
        position="absolute"
        left="10%"
        top="-5%"
        className="stillQuestionLight"
      />
    </Box>
  );
};

export default StillQuestions;
