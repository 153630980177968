import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  CheckIcon,
  ChevronRightIcon,
  PhoneIcon,
  Search2Icon,
} from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import "../App.css";
const Pricing = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  return (
    <Flex direction={"column"} align={"center"}>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
        width={"100%"}
      >
        <Flex
          align="center"
          justify={"center"}
          minH="25vh"
          direction={"column"}
        >
          <Heading
            as="h1"
            size={["md", "xl"]}
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center", "left", "left"]}
          >
            Plans Designed To Help You Scale
          </Heading>
        </Flex>
      </Flex>
      <Flex
        position={"relative"}
        justify={"center"}
        backgroundColor={"white"}
        w={"100%"}
        // h={["900px", ""]}
      >
        <Flex
          backgroundColor={"white"}
          width={["80%", "50%"]}
          align={["", "center"]}
          position={["relative", "absolute"]}
          top={[-10, -20]}
          //   padding={2}
          justify={"space-between"}
          shadow={"xl"}
          h={["800px", "400px"]}
          //   bgColor={"red"}
        >
          <Stack
            direction={["column", "row"]}
            h={"400px"}
            p={4}
            w={"100%"}
            // flex={1}
          >
            <Flex flex={1} p={[2, 6]} direction={"column"} gap={7}>
              <Heading size={"lg"}>Starter</Heading>
              <Text>
                For small brands that want to grow their affiliate network
              </Text>
              <Flex align={"baseline"}>
                <Heading size={"lg"}>$59/</Heading>
                <Text>Billed monthly</Text>
              </Flex>
              {/* <Link to="https://app.metricks.io/login">
                <Button
                  color="#fff"
                  backgroundColor="#7211D4"
                  borderRadius={50}
                  py="7"
                  px="6"
                  lineHeight="1"
                  size="md"
                  mt={10}
                  //   rightIcon={<ChevronRightIcon />}
                >
                  Start Now
                </Button>
              </Link> */}
            </Flex>
            {/* {isDesktop ? (
              <Divider orientation="vertical" flex={1} />
            ) : (
              <Divider orientation="horizontal" flex={1} />
            )} */}
            <Flex
              flex={1}
              p={6}
              pl={7}
              borderLeftColor={"#c4c4c4"}
              borderLeftWidth={0.5}
              direction={"column"}
              gap={5}
            >
              <Heading size={"sm"} color={"#27374CE5"}>
                Included -
              </Heading>
              <Flex gap={3}>
                <Circle bgColor={"#eee2fa"} p={1.5}>
                  <CheckIcon color={"#7211D4"} fontSize={12} />
                </Circle>
                <Text fontSize={14}>Unlimited Affiliates</Text>
              </Flex>
              <Flex gap={3}>
                <Circle bgColor={"#eee2fa"} p={1.5}>
                  <CheckIcon color={"#7211D4"} fontSize={12} />
                </Circle>
                <Text fontSize={14}>Unlimited Campaigns</Text>
              </Flex>
              <Flex gap={3}>
                <Circle bgColor={"#eee2fa"} p={1.5}>
                  <CheckIcon color={"#7211D4"} fontSize={12} />
                </Circle>
                <Text fontSize={14}>Unlimited Organizations</Text>
              </Flex>
              <Flex gap={3}>
                <Circle bgColor={"#eee2fa"} p={1.5}>
                  <CheckIcon color={"#7211D4"} fontSize={12} />
                </Circle>
                <Text fontSize={14}>Unlimited Organizations</Text>
              </Flex>
              <Flex gap={3}>
                <Circle bgColor={"#eee2fa"} p={1.5}>
                  <CheckIcon color={"#7211D4"} fontSize={12} />
                </Circle>
                <Text fontSize={14}>Fraud Control</Text>
              </Flex>
              <Flex gap={3}>
                <Circle bgColor={"#eee2fa"} p={1.5}>
                  <CheckIcon color={"#7211D4"} fontSize={12} />
                </Circle>
                <Text fontSize={14}>Custom Affiliate Onboarding</Text>
              </Flex>
              <Flex gap={3}>
                <Circle bgColor={"#eee2fa"} p={1.5}>
                  <CheckIcon color={"#7211D4"} fontSize={12} />
                </Circle>
                <Text fontSize={14}>Find new Affiliates</Text>
              </Flex>
            </Flex>
          </Stack>
        </Flex>
      </Flex>
      <Flex h={"500px"}> </Flex>
    </Flex>
  );
};

export default Pricing;
