import React, { useEffect } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
function TermsandCondition() {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Flex direction={"column"}>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
      >
        <Flex
          align="center"
          justify={"center"}
          minH="25vh"
          direction={"column"}
        >
          <Heading
            as="h1"
            size="xl"
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center", "left", "left"]}
          >
            Terms of Service
          </Heading>
          <Box w={"70%"}>
            <Text textAlign={"center"}>
              Please read these Terms of Service carefully as they contain
              important information for the use of metricks.io
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex direction={"column"} my={20} width={"100%"} align={"center"}>
        <Flex
          direction={"column"}
          borderColor={"black"}
          borderWidth={1}
          width={"80%"}
          // align={"center"}
          p={10}
        >
          <Text>
            These Terms Of Service Constitute An Agreement (This “Agreement”)
            For The Provision Of Cloud Services By metricks Inc. And Any Person
            Or Entity Opening An Online Account With Tricks Inc (“Customer”) As
            Specified In The Information Provided By Customer To metricks In The
            Online Registration Form. These Terms And Conditions ("Terms",
            "Terms And Conditions") Govern Your Relationship With metricks
            Website (The "Service") Operated By metricks Inc ("Us", "We", Or
            "Our"). This Agreement Is Effective As Of The Date Customer Clicks
            “Accepted And Agreed To” Or A Similar Button In The Process Of
            Opening An Online Account With metrickss Inc(The “Effective Date”).
            Customer’s Use Of And metricks's Provision Of The Cloud Services And
            metricks System (Each As Defined Below) Are Governed By This
            Agreement.
          </Text>
          <Text>
            This Agreement is effective as of the date Customer clicks “Accepted
            and Agreed To” or a similar button in the process of opening an
            online account with metricks Inc (the “Effective Date”). Customer’s
            use of and metricks's provision of the Cloud Services and metricks
            System (each as defined below) are governed by this Agreement.
          </Text>

          <Heading mt={10}>Website Terms and Conditions of Use</Heading>
          <Text mt={10}>
            Please Read These Terms Of Service Carefully Before Using The
            Service As They Contain Important Information Regarding The Legal
            Rights, Remedies And Obligations. Your Access To And Use Of The
            Service Is Conditioned On Your Acceptance Of And Compliance With
            These Terms. These Terms Apply To All Visitors, Users And Others Who
            Access Or Use The Service.
          </Text>
          <Text mt={5}>
            By Accessing This Website, Accessible From Http://metricks.io, You
            Are Agreeing To Be Bound By These Website Terms And Conditions Of
            Use And Agree That You Are Responsible For The Agreement With Any
            Applicable Local Laws. If You Disagree With Any Of These Terms, You
            Are Prohibited From Accessing This Site. The Materials Contained In
            This Website Are Protected By Copyright And Trademark Law. You Agree
            That metricks Reserves The Right To Update And Change These Terms
            From Time To Time Without Notice.
          </Text>
          <Text mt={5}>
            Any New Updates, Features Or Options That Will Be Added To The
            Service, Including The Release Of New Tools And Resources, Shall Be
            Subject To These Terms. Please Note That These Terms May Be Amended
            From Time To Time. In Continuing To Use The Service You Confirm That
            You Accept The Then-Current Terms And Conditions In Full At The Time
            You Use The Service.
          </Text>

          <Heading mt={10}>Definitions</Heading>
          <Text mt={10}>
            metricks Is A Company That Enables Advertisers (“Advertisers”) To
            Market Their Products Online With The Help Of Publishers
            (“Affiliates”). metricks Contracts Only With Advertisers And Have No
            Responsibility In The Relationship Between Advertisers And
            Affiliates. metricks Offers Tools To Improve, Register And
            Facilitate Online Marketing Through Affiliates (The “Service”). The
            Payment Of Affiliates Shall Always Be The Sole Responsibility Of The
            Advertisers And metricks Shall Therefore Never Be Held Liable For
            Any Damages Resulting From Any Failure Of The Advertiser To Pay An
            Affiliate In A Complete And Timely Manner.
          </Text>
          <Text mt={5}>
            By Accepting These Terms, Any Advertiser Using Our Service Warrants
            That He Will Pay The Relevant Affiliate In A Complete And Timely
            Manner.
          </Text>

          <Heading mt={10}>Registration</Heading>
          <Text mt={10}>
            To Make Use Of The Service, It Is Necessary To Create An Account
            (“Account”). The Service Is Intended Solely For Persons Who Are 18
            Or Older. Any Access To Or Use Of The Service By Anyone Under 18 Is
            Expressly Prohibited. By Accessing Or Using The Service You
            Represent And Warrant That You Are 18 Or Older.
          </Text>
          <Heading mt={10}>Accounts</Heading>
          <Text mt={10}>
            When You Create An Account With Us, You Must Provide Us With
            Information That Is Accurate, Complete, And Current At All Times.
            Failure To Do So Constitutes A Breach Of The Terms, Which May Result
            In Immediate Termination Of Your Account On Our Service. To Create
            Your Account You Will Have To Provide Your Full Name With A Current
            Email Address And Credit Card Details (Only Advertisers).
          </Text>
          <Text mt={5}>
            You Are Responsible For Safeguarding The Password That You Use To
            Access The Service And For Any Activities Or Actions Under Your
            Password, Whether Your Password Is With Our Service Or A Third-Party
            Service.
          </Text>
          <Text mt={5}>
            You Agree Not To Disclose Your Password To Any Third Party. You Must
            Notify Us Immediately Upon Becoming Aware Of Any Breach Of Security
            Or Unauthorized Use Of Your Account.
          </Text>
          <Text mt={5}>
            You May Not Use As A Username The Name Of Another Person Or Entity
            Or That Is Not Lawfully Available For Use, A Name Or Trademark That
            Is Subject To Any Rights Of Another Person Or Entity Other Than You
            Without Appropriate Authorization, Or A Name That Is Otherwise
            Offensive, Vulgar Or Obscene.
          </Text>
          <Text mt={5}>
            Your Account Is Strictly Personal And May Not Be Used By Anyone
            Else. You May Not Impersonate Any Other Person In Any Registration
            Whether Or Not That Other Person Is A User Of The Service.
          </Text>
          <Text mt={5}>
            If You Manage More Than One Business Entity, You Must Create A New
            Account For Each And Every Different Business Entity. If You Fail To
            Do So, We Reserve The Right At Our Discretion To Block, Cancel Or
            Remove An Account Of Any Person Who In Our Opinion Possesses More
            Than One Account At Any Time.
          </Text>
          <Text mt={5}>
            You May Not Use The Service For Any Illegal Or Unauthorized Purpose.
            You Must Not, In The Use Of The Service, Violate Any Laws In Your
            Jurisdiction (Including But Not Limited To Copyright Laws).
          </Text>

          <Heading mt={10}>Fees</Heading>
          <Text mt={10}>
            A Valid Payment Method, Including A Credit Card Or PayPal, Is
            Required For Paying Accounts. If Neither Of These Is Present On The
            Account, The Account May Be Closed
          </Text>
          <Text mt={5}>
            metricks, In Its Sole Discretion And At Any Time, May Modify The
            Subscription Fees For The Subscriptions. Any Subscription Fee Change
            Will Become Effective At The End Of The Then-Current Billing Cycle.
            You Hereby Agree To Keep Such Valid Credit Card And/Or Bank Account
            In Effect With Sufficient Credit Limit To Enable metricks To Charge
            The Subscription Fees Every Month, Quarter Or Another Period Of Each
            Term, As Applicable, And Not To Challenge Such Charges Or To Request
            Reversal Of Such Charges.
          </Text>

          <Heading mt={10}>Taxes</Heading>
          <Text mt={10}>
            All Fees Are Exclusive Of All Taxes, Levies, Or Duties Imposed By
            Taxing Authorities, And You Shall Be Responsible For Payment Of All
            Such Taxes, Levies, Or Duties. metricks Has No Responsibility Or
            Legal Power To Ensure The Payment Of The Advertiser And Accepts No
            Responsibility Or Liability In The Event That The Affiliate Fails To
            Arrange Or Collect Payment From The Advertiser. metricks May Add Any
            Such Taxes To The Applicable Fees.
          </Text>
          <Heading mt={10}>Refunds</Heading>
          <Text mt={10}>
            The Service Is Billed In Advance On A Monthly Or Yearly Basis And Is
            Non-Refundable. There Will Be No Refunds Or Credits For Partial
            Months Of Service, Upgrade/Downgrade Refunds, Or Refunds For Months
            Unused With An Open Account.
          </Text>
          <Heading mt={10}>Fee Changes</Heading>
          <Text mt={10}>
            metricks, In Its Sole Discretion And At Any Time, May Modify The
            Subscription Fees For The Subscriptions. Any Subscription Fee Change
            Will Become Effective At The End Of The Then-Current Billing Cycle.
            metricks Will Provide You With Reasonable Prior Notice Of Any Change
            In Subscription Fees To Give You An Opportunity To Terminate Your
            Subscription Before Such Change Becomes Effective. Your Continued
            Use Of The Service After The Subscription Fee Change Comes Into
            Effect Constitutes Your Agreement To Pay The Modified Subscription
            Fee Amount.
          </Text>
          <Text mt={5}>
            metricks Shall Not Be Liable To You Or To Any Third Party For Any
            Modification, Price Change, Suspension Or Discontinuance Of The
            Service.
          </Text>
          <Heading mt={10}>Subscription and Tracking</Heading>
          <Text mt={10}>
            If You Choose To Upgrade Or Downgrade Your Service, Your Credit Card
            Will Automatically Be Charged The New Rate On Your Next Billing
            Cycle.
          </Text>
          <Text mt={5}>
            .Downgrading Your Service May Cause The Loss Of Content, Features,
            Or Capacity Of Your Account. metricks Does Not Accept Any Liability
            For Such Loss.
          </Text>
          <Text mt={5}>
            Downgrading Your Service To A Non-White Label Level Will Cause All
            Affiliates Who Registered While The White Label Service Was
            Activated, To Be Deactivated
          </Text>
          <Text mt={5}>
            Every Service Level Has Its Own Number Of Tracking Events (“Tracking
            Events”). Whenever The Number Of Tracking Events, As Agreed Upon And
            Limited In The Relevant Service Level, Is Exceeded During The
            Subscription Period, We Will Calculate The Price For The Surplus
            Tracking Events In Accordance With The Overage Fees Listed On The
            Pricing Page As Part Of Service Level. In Such A Case, The Extra
            Costs Of The Surplus Tracking Events Will Be Added To The Relevant
            Monthly Fee.
          </Text>

          <Heading mt={10}>Cancellation and Termination</Heading>
          <Text mt={10}>
            We May Terminate Or Suspend Your Account Immediately, Without Prior
            Notice Or Liability, For Any Reason Whatsoever, Including Without
            Limitation If You Breach The Terms. Upon Termination, Your Right To
            Use The Service Will Immediately Cease. If You Wish To Terminate
            Your Account, You May Simply Discontinue Using The Service.
          </Text>
          <Text mt={5}>
            If We Exercise Our Discretion Under These Terms To Do So, Any Or All
            Of The Following Can Occur With Or Without Prior Notice Or
            Explanation To You: (A) Your Account Will Be Deactivated Or
            Suspended, Your Password Will Be Disabled, And You Will Not Be Able
            To Access The Service, Or Receive Assistance From Our Customer
            Service.
          </Text>
          <Text mt={5}>
            You May Cancel Your Account At Any Time From The Subscription &
            Billing Page. You Are Solely Responsible For Properly Cancelling
            Your Account. Please Note That If Your Account Is Cancelled, We Do
            Not Have An Obligation To Delete Or Return To You Any Content.
          </Text>
          <Text mt={5}>
            All Of Your Content (Text And Files) May Be Immediately Deleted From
            The Service Upon Cancellation. This Information Cannot Be Recovered
            Once Your Account Is Cancelled.
          </Text>
          <Text mt={5}>
            If You Cancel The Service Before The End Of Your Current Service
            Period, Your Cancellation Will Take Effect Immediately And You Will
            Not Be Charged Again.
          </Text>
          <Text mt={5}>
            metricks, In Its Sole Discretion, Has The Right To Suspend Or
            Terminate Your Account And Refuse Any And All Current Or Future Use
            Of The Service, Or Any Other Service From metricks, For Any Reason
            At Any Time. Such Termination Of The Service Will Result In The
            Deactivation Or Deletion Of Your Account Or Your Access To Your
            Account, And The Forfeiture And Relinquishment Of All Content In
            Your Account. metricks Reserves The Right To Refuse Service To
            Anyone For Any Reason At Any Time
          </Text>

          <Heading mt={10}>Intellectual Property</Heading>
          <Text mt={10}>
            The Service And Its Original Content, Features And Functionality Are
            And Will Remain The Exclusive Property Of metricks Inc And Its
            Licensors. The Service Is Protected By Copyright, Trademark, And
            Other Laws Of Both The United States Of America And Foreign
            Countries. Our Trademarks And Trade Dress May Not Be Used In
            Connection With Any Product Or Service Without The Prior Written
            Consent Of metricks.
          </Text>
          <Heading mt={10}>Disclaimer</Heading>
          <Text mt={10}>
            All The Materials On metricks's Website Are Provided "As Is".
            metricks Makes No Warranties, May It Be Expressed Or Implied,
            Therefore Negates All Other Warranties. Furthermore, metricks Does
            Not Make Any Representations Concerning The Accuracy Or Reliability
            Of The Use Of The Materials On Its Website Or Otherwise Relating To
            Such Materials Or Any Sites Linked To This Website
          </Text>

          <Text mt={5}>
            metricks, Its Subsidiaries, Affiliates, And Its Licensors Do Not
            Warrant That A) The Service Will Function Uninterrupted, Secure Or
            Available At Any Particular Time Or Location; B) Any Errors Or
            Defects Will Be Corrected; C) The Service Is Free Of Viruses Or
            Other Harmful Components, Or D) The Results Of Using The Service
            Will Meet Your Requirements.
          </Text>
          <Text mt={5}>
            We Do Not Screen User-Generated Content Or Information On The
            Service And We Cannot Give Any Assurance As To Its Accuracy Or
            Completeness. Users Of The Service Are Expressly Asked Not To
            Publish Any Defamatory, Misleading Or Offensive Content Or Any
            Content Which Infringes Any Other Persons Intellectual Property
            Rights (Eg. Copyright). Any Such Content Is Contrary To Our Policy
            We Do Not Accept Liability In Respect Of Such Content, And The User
            Responsible Will Be Personally Liable For Any Damages Or Other
            Liability Arising And You Agree To Indemnify Us In Relation To Any
            Liability We May Suffer As A Result Of Any Such Content.
          </Text>
          <Text mt={5}>
            metricks Has The Right In Its Sole Discretion To Refuse Or Remove
            Any Content That Is Available Via The Service.
          </Text>
          <Text mt={5}>
            The Service And This Website Or Any Portion Of The Service Or The
            Website May Not Be Reproduced, Duplicated, Copied, Sold, Resold,
            Visited, Or Otherwise Exploited For Any Commercial Purpose Without
            Our Express Written Consent. You May Not Systematically Extract
            And/Or Re-Utilize Parts Of The Contents Of The Service Without Our
            Express Written Consent. In Particular, You May Not Utilise Any Data
            Mining, Robots, Or Similar Data Gathering And Extraction Tools To
            Extract (Whether Once Or Many Times) For Re-Utilization Of Any
            Substantial Parts Of The Service Without Our Express Written
            Consentilisation Of Any Substantial Parts Of The Service Without Our
            Express Written Consent.
          </Text>
          <Text mt={5}>
            All Content Posted On The Service Must Comply With The Relevant
            Copyright Law. We Claim No Intellectual Property Rights Over The
            Material You Provide To The Service. Your Profile And Materials
            Uploaded Remain Yours. However, If You Or Anyone Else With Access To
            Your Account Makes Any Content Public, You Agree To Allow Others To
            View And Share Your Content.
          </Text>

          <Heading mt={10}>Limitation and Liability</Heading>
          <Text mt={10}>
            In No Event Shall metricks, Nor Its Directors, Employees, Partners,
            Agents, Suppliers, Or Affiliates, Be Liable For Any Indirect,
            Incidental, Special, Consequential Or Punitive Damages, Including
            Without Limitation, Loss Of Profits, Data, Use, Goodwill, Or Other
            Intangible Losses, Resulting From (I) Your Access To Or Use Of Or
            Inability To Access Or Use The Service; (Ii) Any Conduct Or Content
            Of Any Third Party On The Service; (Iii) Any Content Obtained From
            The Service; (Iv) Unauthorized Access, Use Or Alteration Of Your
            Transmissions Or Content, Whether Based On Warranty, Contract, Tort
            (Including Negligence) Or Any Other Legal Theory, Whether Or Not We
            Have Been Informed Of The Possibility Of Such Damage, And Even If A
            Remedy Set Forth Herein Is Found To Have Failed Of Its Essential
            Purpose.
          </Text>
          <Text mt={5}>
            Your Access To The Service May Be Occasionally Restricted To Allow
            For Repairs, Maintenance Or The Introduction Of New Facilities Or
            Services. We Will Attempt To Restore The Service As Soon As We
            Reasonably Can.
          </Text>
          <Text mt={5}>
            From Time To Time It May Be Necessary To Suspend Access To The
            Service For A Period Of Time And Any Such Interruptions Shall Not
            Constitute A Breach By Us Of These Terms.
          </Text>
          <Text mt={5}>
            The User Agrees That The Above Exclusions Of Liability Are
            Reasonable In All The Circumstances, Especially In Light Of The Fact
            That Our Service Includes Only The Provision Of The Service And
            Responsibility For Any Payment To Affiliates Lies Solely With The
            Advertiser
          </Text>

          <Heading mt={10}>Publicity</Heading>
          <Text mt={10}>
            In Further Consideration Of The Rights Granted Hereunder To The
            User, User Agrees To Permit metricks To Use User’s Name And
            Trademarks And Service Marks To Identify User As metricks's Customer
            On The Website, In metricks's Marketing Materials And In Other Sales
            And Marketing Activities, Provided That User Does Not Notify
            metricks In Writing Of Its Revocation Of Such Permission. User
            Agrees To Cooperate With metricks In Reasonable Publicity Efforts
            Involving The System, Such As, For Example, Media Releases And
            Marketing Materials In Accordance With metricks's Reasonable
            Request.
          </Text>
          <Heading mt={10} color={"#7211D4"}>
            Your Privacy
          </Heading>
          <Text mt={5}>Please read our privacy policy</Text>

          <Heading mt={10}>General</Heading>
          <Text mt={10}>
            Your Use Of The Service Is At Your Sole Risk. The Service Is
            Provided On An “As Is” And “As Available” Basis.
          </Text>
          <Text mt={5}>
            You Understand That metricks Uses Third Party Vendors And Hosting
            Partners To Provide The Necessary Hardware, Software, Networking,
            Storage, And Related Technology Required To Run The Service.
          </Text>
          <Text mt={5}>
            You Must Not Modify, Adapt Or Hack The Service Or Modify Another
            Website So As To Falsely Imply That It Is Associated With The
            Service Or metricks.
          </Text>
          <Text mt={5}>
            You May Not Reverse Engineer Or Reuse Source Code That Is In Public
            View. This Includes Any And All Javascript.
          </Text>
          <Text mt={5}>
            Verbal, Physical, Written Or Other Abuse (Including Threats Of Abuse
            Or Retribution) Of Any metricks Customer, Employee, Member, Or
            Officer Will Result In Immediate Account Termination.
          </Text>
          <Text mt={5}>
            You Understand That The Technical Processing And Transmission Of The
            Service, Including Your Content, May Be Transferred Unencrypted And
            Involve (A) Transmissions Over Various Networks; And (B) Changes To
            Conform And Adapt To The Technical Requirements Of Connecting
            Networks Or Devices.
          </Text>
          <Text mt={5}>
            You Must Not Upload, Post, Host, Or Transmit Unsolicited Emails Or
            “Spam” Messages.
          </Text>
          <Text mt={5}>
            You Must Not Transmit Any Worms Or Viruses Or Any Code Of A
            Destructive Nature.
          </Text>
          <Text mt={5}>
            If Your Bandwidth Usage Exceeds The Monthly Limit Or Significantly
            Exceeds The Average Bandwidth Usage, We Reserve The Right To
            Immediately Suspend Your Account Or Throttle Your File Hosting Until
            You Can Reduce Your Bandwidth Consumption Or Upgrade Your Account.
          </Text>
          <Text mt={10}>We Do Not Warrant That</Text>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#7211D4"} size={3}></Circle>
            <Text>The Service Will Meet Your Specific Requirements,</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#7211D4"} size={3}></Circle>
            <Text>
              The Service Will Be Uninterrupted, Timely, Secure, Or Error-Free,
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#7211D4"} size={3}></Circle>
            <Text>
              {" "}
              The Results That May Be Obtained From The Use Of The Service Will
              Be Accurate Or Reliable,
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#7211D4"} size={3}></Circle>
            <Text>
              {" "}
              The Quality Of Any Products, The Service, Information, Or Other
              Material Purchased Or Obtained By You Through The Service Will
              Meet Your Expectations.
            </Text>
          </Flex>
          <Text mt={10}>
            The Failure Of metricks To Exercise Or Enforce Any Right Or
            Provision Of These Terms Shall Not Constitute A Waiver Of Such Right
            Or Provision. These Terms Constitute The Entire Agreement Between
            You And metricks And Govern Your Use Of The Service, Superseding Any
            Prior Agreements Between You And metricks (Including, But Not
            Limited To, Any Prior Versions Of These Terms
          </Text>
          <Text mt={5}>
            Neither Party Will Be Liable To The Other Or Be Deemed To Be In
            Breach Of These Terms By Reason Of Any Delay In Performing, Or Any
            Failure To Perform, Any Of Their Obligations If The Delay Or Failure
            Was Due To Any Event Of Force Majeure Beyond Their Reasonable
            Control Such As Severe Weather, Subsidence, Power Or Other Utility
            Cut-Offs, Burglary, Natural Disaster, Strikes, Governmental Action,
            Terrorism, War Or Civil Unrest.
          </Text>
          <Text mt={5}>
            The Parties Agree That These Terms Are Fair And Reasonable In All
            The Circumstances. However, If Any Provision Of These Terms Is Held
            Not To Be Valid By A Court Of Competent Jurisdiction But Would Be
            Valid If Part Of The Wording Were Deleted, Then Such Provision Shall
            Apply With Such Deletions As May Be Necessary To Make It Valid. If
            Any Of The Provisions In These Terms Are Held Not To Be Valid The
            Remaining Provisions Of These Terms Shall Remain In Full Force And
            Effect.
          </Text>
          <Text mt={5}>
            If You Breach These Terms And Conditions And We Decide To Take No
            Action Or Neglect To Do So, Then We Will Still Be Entitled To Take
            Action And Enforce Our Rights And Remedies For Any Other Breach.
          </Text>
          <Text mt={5}>
            We May Make Changes To The Format Of The Service Or The Content Of
            The Service At Any Time Without Notice.
          </Text>
          <Text mt={5}>
            Advertisers Will Be Solely Responsible For The Registration Of
            Affiliates. Such Registration Can Be Executed For Instance Through A
            Branded Registration Page, As A Result Of Which Affiliates Might
            Receive Transactional Emails From metricks.
          </Text>
          <Text mt={5}>
            The Advertiser Will Impose These General Terms And Conditions
            Unconditionally Upon All Its Affiliates And Is And Will Be On All
            Circumstances Liable For Any Infringement On These Terms By Any
            Affiliate And/Or Any Claim Brought Forward By One Or More
            Affiliates.
          </Text>
          <Text mt={5}>
            Affiliates Who Registered Before A White Label Was Activated By The
            Advertiser, Will Not Be Able To Use The Custom Subdomain Or The
            Domain Alias To Log In On The Affiliate Portal. As A Result Of This,
            They Will Not Be Able To See The Custom Branding. They Will Be Able
            However To Promote The Programs Of The Advertiser And All Affiliate
            Links Will Remain Active.
          </Text>
          <Text mt={5}>
            Once A Domain Alias Has Been Created, Without Any Costs, This Alias
            Can Be Changed During The First 7 Days. After This Term Of 7 Days,
            metricks Will Be Entitled To Charge Extra Costs For Such Change.
          </Text>
          <Heading mt={10}>Acceptance</Heading>
          <Text mt={10}>
            The User Acknowledges Having Read This Agreement, Understands It And
            Agrees To Be Bound By Its Terms. The Person Accepting This Agreement
            On The User’s Behalf Through metricks's Online Registration Process
            Or By Means Of A User Order Represents That He/She Has The Authority
            To Bind User To These Terms And Conditions.
          </Text>
          <Text mt={5}>
            The User Further Acknowledges That This Agreement Does Not Have To
            Be Signed In Order To Be Binding. User Will Have Indicated Its
            Assent To The Terms Of The Agreement By Clicking On The "Accepted
            And Agreed To" (Or Similar Button) That Is Presented To The User At
            The Time Of Its Opening Of An Online Account With metricks And
            Submitting An Order For The Cloud Services Through metricks's Online
            Registration Process On The Website
          </Text>

          <Heading mt={10}>Terms and Service Modification</Heading>
          <Text mt={10}>
            metricks May Revise These Terms Of Service For Its Website At Any
            Time Without Prior Notice. By Using This Website, You Are Agreeing
            To Be Bound By The Current Version Of These Terms And Conditions Of
            Use. If A Revision Is Material, We Will Try To Provide At Least 30
            Days Notice Prior To Any New Terms Taking Effect. What Constitutes A
            Material Change Will Be Determined At Our Sole Discretion.
          </Text>
          <Text mt={5}>
            User’s Continued Use Of The Services Following The Effective Date Of
            An Amendment Will Confirm User’s Consent Thereto. This Agreement May
            Not Be Amended In Any Other Way Except Through A Written Agreement
            By Authorized Representatives Of Each Party. metricks May Revise The
            Privacy Policy And Any Other metricks Policy At Any Time By Posting
            A New Version Of Either At The Website, And Such New Version Will
            Become Effective On The Date It Is Posted.
          </Text>
          <Heading mt={10}>Contact Us</Heading>
          <Text mt={10}>
            If You Have Any Questions About These Terms, Please Contact Us Over
            Live Chat Or Email Support@metricks.io
          </Text>
        </Flex>
      </Flex>
      <Flex
        style={{
          background:
            "conic-gradient(from 5.87deg at 58.99% 0%, rgba(114, 17, 212, 0) -118.12deg, rgba(114, 17, 212, 0.56) 20.77deg, rgba(114, 17, 212, 0) 241.88deg, rgba(114, 17, 212, 0.56) 380.77deg)",
        }}
        justify={"center"}
      >
        <Flex
          w={"80%"}
          align={"center"}
          justify={{ md: "center", base: "center" }}
        >
          <Flex
            align="center"
            // direction={{ base: "column-reverse", md: "row" }}
            w={{ base: "100%", md: "50%" }}
            py={20}
          >
            <Flex
              //   divider={<StackDivider borderColor='gray.200' />}
              gap={10}
              align={{ base: "center", md: "center" }}
              // justify={{ md: "flex-start", base: "center" }}
              // backgroundColor={"red"}
              direction={"column"}
            >
              <Heading textAlign={{ base: "center", md: "center" }}>
                Take your affiliate marketing to the next level.
              </Heading>

              <Flex alignItems={"flex-start"} gap={5}>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#000"
                    backgroundColor="#fff"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Sign up
                  </Button>
                </Link>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#fff"
                    backgroundColor="#7211D4"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Talk to a Human
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TermsandCondition;
