import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  ArrowForwardIcon,
  CheckIcon,
  ChevronRightIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import { BsFillPinAngleFill } from "react-icons/bs";
import { NavContext } from "../../context/NavContext";
import { useLocation } from "react-router-dom";
const AffShopify = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { isAdvertiser, setisAdvertiser } = useContext(NavContext);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");

  const gridData = [
    {
      description: "Easy setup",
    },
    {
      description: "Extends to target market ",
    },
    {
      description: "Unlimited growth potential",
    },
    {
      description: "Integrates with major platforms ",
    },
    {
      description: "Customizable dashboards",
    },
    {
      description: "Free traffic to your website ",
    },
    {
      description: "It increases the brand’s online visibility",
    },
  ];

  interface FeatureProps {
    title: string;
    text: string;
    icon: React.ReactElement;
  }

  const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
      <Stack borderWidth={0.5} borderColor={"#c4c4c4"} p={10}>
        <Flex
          //   w={16}
          //   h={16}
          align={"center"}
          justify={"center"}
          //   color={"white"}
          rounded={"full"}
          //   bg={"gray.100"}
          mb={1}
          p={5}
        >
          <Circle bgColor={"#eee2fa"} p={1.5}>
            {icon}
          </Circle>
        </Flex>

        <Text color={"gray.600"} textAlign={"center"}>
          {text}
        </Text>
      </Stack>
    );
  };
  return (
    <>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
      >
        <Flex
          align="center"
          justify={{ base: "center", md: "space-between", xl: "space-between" }}
          direction={{ base: "column-reverse", md: "row" }}
          w={{ base: "90%", lg: "80%" }}
          //   backgroundColor={"red"}
          minH="60vh"
        >
          <Stack
            spacing={10}
            w={{ base: "100%", md: "40%" }}
            align={["center", "center", "flex-start", "flex-start"]}
            ml={isDesktop ? "20px" : "0px"}
            //   backgroundColor={"red"}
          >
            <Heading
              as="h1"
              size="2xl"
              fontWeight="700"
              color="primary.800"
              textAlign={["center", "center", "left", "left"]}
            >
              Affiliate Software For Shopify
            </Heading>

            <Text
              as="h2"
              size="md"
              color="primary.800"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["center", "center", "left", "left"]}
              //   fontFamily={"heading"}
            >
              With the help of Metricks, Shopify store owners can increase the
              profitability of their businesses by setting up their own
              affiliate networks and hiring affiliates to market and sell their
              products.
            </Text>

            {/* <Link to="https://app.metricks.io/login">
              <Button
                color="#fff"
                backgroundColor="#7211D4"
                borderRadius={50}
                py="7"
                px="6"
                lineHeight="1"
                size="md"
                rightIcon={<ChevronRightIcon />}
              >
                Start Now
              </Button>
            </Link> */}
          </Stack>
          <Flex
            w={{ base: "100%", sm: "60%", md: "50%" }}
            mb={{ base: 12, md: 0 }}
            //   backgroundColor={"red"}
            justify={"flex-end"}
          >
            <Image src="/assets/png/shopify.svg" rounded="1rem" />
          </Flex>
        </Flex>
      </Flex>

      <Flex align={"center"} justify={"center"} mb={30} py={20}>
        <Flex align={"center"} justify={"center"} direction={"column"} gap={10}>
          <Heading
            as="h1"
            size="xl"
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center"]}
            w={"80%"}
          >
            How Metricks Can Work For You?
          </Heading>

          <Text
            as="h2"
            size="md"
            color="primary.800"
            opacity="0.8"
            fontWeight="400"
            lineHeight={1.5}
            textAlign={["left", "left", "center", "center"]}
            w={"60%"}
          >
            Metricks, the best Shopify affiliate marketing software, maintains
            and enhances all your affiliate and influencer programs.
          </Text>

          <Box
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
            shadow={"md"}
            width={"65%"}
            position={"relative"}
            p={10}
          >
            <Circle
              size="60px"
              bg="white"
              color="#7139d4"
              position={"absolute"}
              top={"-30px"}
              background="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
              _hover={{
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 12px 20px, rgba(0, 0, 0, 0.2) 0px 3px 6px",
                transform: "translateY(-2px)",
              }}
            >
              <BsFillPinAngleFill size={"25px"} />
            </Circle>
            <Text
              as="h2"
              size="md"
              color="#27374C"
              opacity="0.8"
              fontWeight="400"
              lineHeight={1.5}
              textAlign={["left", "left"]}
              w={"100%"}
            >
              Metricks performs various tasks, including monitoring performance,
              insightful data, and increasing affiliate earnings. provides
              referral programs that entice customers to represent your brand.
              This increases customer retention.
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Flex
        align={"center"}
        justify={"center"}
        direction={"column"}
        gap={10}
        my={10}
      >
        <Heading fontSize="30px" textAlign={["center", "left"]}>
          Benefits Of Metricks Affiliate Software For Shopify
        </Heading>
        <Box w={"80%"}>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            // spacing={10}
            borderWidth={0.5}
            borderColor={"#c4c4c4"}
          >
            {gridData.map((item, i) => (
              <Feature
                icon={<CheckIcon color={"#7211D4"} fontSize={12} w={5} h={5} />}
                title={"Lifetime Support"}
                text={item.description}
                key={i}
              />
            ))}
          </SimpleGrid>
        </Box>
      </Flex>

      <Flex
        style={{
          background:
            "conic-gradient(from 5.87deg at 58.99% 0%, rgba(114, 17, 212, 0) -118.12deg, rgba(114, 17, 212, 0.56) 20.77deg, rgba(114, 17, 212, 0) 241.88deg, rgba(114, 17, 212, 0.56) 380.77deg)",
        }}
        justify={"center"}
        width={"100%"}
      >
        <Flex
          w={"80%"}
          align={"center"}
          justify={{ md: "center", base: "center" }}
        >
          <Flex
            align="center"
            // direction={{ base: "column-reverse", md: "row" }}
            w={{ base: "100%", md: "50%" }}
            py={20}
          >
            <Flex
              //   divider={<StackDivider borderColor='gray.200' />}
              gap={10}
              align={{ base: "center", md: "center" }}
              // justify={{ md: "flex-start", base: "center" }}
              // backgroundColor={"red"}
              direction={"column"}
            >
              <Heading textAlign={{ base: "center", md: "center" }}>
                Start powering your brand's word of mouth efforts with Metricks.
              </Heading>

              {/* <Flex alignItems={"flex-start"} gap={5}>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#000"
                    backgroundColor="#fff"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Start now
                  </Button>
                </Link>
              </Flex> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default AffShopify;
