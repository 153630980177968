import {
  Box,
  Circle,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineSlack, AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn, FaFacebookF, FaDiscord } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { Link, NavLink, Navigate } from "react-router-dom";

const Footer = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  return (
    <Flex
      w={"100%"}
      justify={"center"}
      backgroundColor={"#f6f9fc"}
      direction={"column"}
      align={"center"}
      position={"relative"}
    >
      <Stack
        direction={!isDesktop ? "column" : "row"}
        spacing="24px"
        w={"80%"}
        py={10}
      >
        <VStack
          spacing={3}
          align={["flex-start", "flex-start"]}
          flex={2}
          // justify={"center"}
        >
          <Image src="/assets/png/METRICKS-main-logo 5.png" w="50" h="50" />
          {/* <Link to={"https://t.me/+BZf_6upktAtiYjlk"} target="_blank">
            <Flex align={"center"} gap={3}>
              <Icon as={FaDiscord} />
              Join our Telegram Community
            </Flex>
          </Link> */}

          {/* <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:Support@metricks.io";
            }}
            target="_blank"
          >
            <Flex
              align={"center"}
              gap={3}
              onClick={() => {
                window.location.href = "Support@metricks.io";
              }}
            >
              <Icon as={GrMail} />
              support@metricks.io
            </Flex>
          </Link> */}
        </VStack>
        <VStack align={["flex-start", "flex-start"]} flex={1} spacing={5}>
          <Text color={"#9394A1"} fontSize={14}>
            Integration for SAAS
          </Text>
          <VStack align={"flex-start"}>
            <Link to={"/stripe"}>
              <Text fontSize={14}>Stripe</Text>
            </Link>
          </VStack>
        </VStack>

        <VStack align={["flex-start", "flex-start"]} flex={1} spacing={5}>
          <Text color={"#9394A1"} fontSize={14}>
            Legal
          </Text>
          <VStack align={"flex-start"}>
            <Link to={"/PrivacyPolicy"}>
              <Text fontSize={14}>Privacy Policy</Text>
            </Link>
            <Link to={"/termsandconditions"}>
              <Text fontSize={14}>Terms of Service</Text>
            </Link>
          </VStack>
        </VStack>
        <VStack align={["flex-start", "flex-start"]} flex={2} spacing={5}>
          <Text color={"#9394A1"} fontSize={14}>
            Integration for E-commerce
          </Text>
          <VStack align={"flex-start"}>
            {/* <Link to={"/ecwid"}>
              <Text>Affiliate software for Ecwid</Text>
            </Link>
            <Link to={"/prestashop"}>
              <Text>Affiliate software for Prestashop</Text>
            </Link>
            <Link to={"/lightspeed"}>
              <Text>Affiliate software for Lightspeed</Text>
            </Link> */}
            <Link to={"/woocommerce"}>
              <Text fontSize={14}> WooCommerce</Text>
            </Link>
            <Link to={"/wix"}>
              <Text fontSize={14}>Wix</Text>
            </Link>
            <Link to={"/paddle"}>
              <Text fontSize={14}>Paddle</Text>
            </Link>
            {/* <Link to={"/paypal"}>
              <Text>Affiliate software for Paypal</Text>
            </Link> */}
            <Link to={"/wordpress"}>
              <Text fontSize={14}>WordPress</Text>
            </Link>
            <Link to={"/shopify"}>
              <Text fontSize={14}>Shopify</Text>
            </Link>
            {/* <Link to={"/squarespace"}>
              <Text>Affiliate software for Squarespace</Text>
            </Link> */}
          </VStack>
        </VStack>

        <VStack align={["flex-start", "flex-start"]} flex={2} spacing={5}>
          <Text color={"#9394A1"} fontSize={14}>
            Contact
          </Text>
          <VStack align={"flex-start"}>
            {/* <Link to={"/ecwid"}>
              <Text>Affiliate software for Ecwid</Text>
            </Link>
            <Link to={"/prestashop"}>
              <Text>Affiliate software for Prestashop</Text>
            </Link>
            <Link to={"/lightspeed"}>
              <Text>Affiliate software for Lightspeed</Text>
            </Link> */}
            <Link
              to={"mailto: support@metricks.io"}
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Text fontSize={14}> Email:</Text>
              <Text fontSize={14} as="ins" textDecoration={"underline"}>
                support@metricks.io
              </Text>
            </Link>

            <Text fontSize={14}>
              Address: Office 9, Dalton House, 60 Windsor Avenue, London, United
              Kingdom, SW19 2RR.
            </Text>
          </VStack>
        </VStack>
      </Stack>
      <Flex
        direction={"row"}
        w={"80%"}
        justify={"space-between"}
        borderTopColor={"#c4c4c4"}
        borderTopWidth={0.5}
        py={2}
        align={"center"}
      >
        <Link to={"https://www.joinpeddle.com/"} target="_blank">
          <Text>Copyright © 2024 Peddle Technologies</Text>
        </Link>
        <Flex align={"center"} display={{ base: "none", md: "flex" }}>
          <Link
            to={"https://youtube.com/@metricks4605?si=qVPCqqep0gyWcC9n"}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={AiFillYoutube} />
            </Circle>
          </Link>
          <Link
            to={"https://www.linkedin.com/company/getmetricks/"}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={FaLinkedinIn} />
            </Circle>
          </Link>
          <Link
            to={"https://instagram.com/metricks.io?igshid=MzRlODBiNWFlZA=="}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={AiFillInstagram} />
            </Circle>
          </Link>
          <Link
            to={"https://www.facebook.com/trackfillate?mibextid=LQQJ4d"}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={FaFacebookF} />
            </Circle>
          </Link>
        </Flex>
        <Flex
          align={"center"}
          display={{ base: "flex", md: "none" }}
          position={"absolute"}
          direction={"column"}
          top={20}
          right={5}
          gap={2}
        >
          <Link
            to={"https://youtube.com/@metricks4605?si=qVPCqqep0gyWcC9n"}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={AiFillYoutube} />
            </Circle>
          </Link>
          <Link
            to={"https://www.linkedin.com/company/getmetricks/"}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={FaLinkedinIn} />
            </Circle>
          </Link>
          <Link
            to={"https://instagram.com/metricks.io?igshid=MzRlODBiNWFlZA=="}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={AiFillInstagram} />
            </Circle>
          </Link>
          <Link
            to={"https://www.facebook.com/trackfillate?mibextid=LQQJ4d"}
            target="_blank"
          >
            <Circle
              size="50px"
              bg="white"
              boxShadow="4.77441e-16px 7.7972px 15.5944px rgba(51, 51, 51, 0.1315), inset -1.15647e-16px -1.88865px 1.88865px #E0E0E0, inset 1.15647e-16px 1.88865px 1.88865px #FFFFFF"
            >
              <Icon as={FaFacebookF} />
            </Circle>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
