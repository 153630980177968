import React, { useContext, useEffect, useState } from "react";
import { NavContext } from "../context/NavContext";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  ArrowForwardIcon,
  ChevronRightIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import Hero from "../components/Hero/Hero";
import IntegrationWith from "../components/IntegrationWith/IntegrationWith";
import Properties from "../components/MetrickProperties/Properties";
import Pricing from "../components/Pricing/Pricing";
import SignupAds from "../components/SignupAds/SignupAds";
import ManageAffiliates from "../components/ManageAffiliatesBanner/ManageAffiliates";
import Hero2 from "../components/Hero/Hero2";
import Properties2 from "../components/MetrickProperties/Properties2";
import Insights from "../components/Insights/Insights";
import Integrations from "../components/IntegrationWith/Integrations";
import Penetrate from "../components/Penetrate/Penetrate";
import Comments from "../components/Comments/Comments";
import Faq from "../components/Faq/Faq";
import Still from "../components/Still/Still";
import Explainer from "../components/Explainer/Explainer";
import { IoArrowForwardSharp } from "react-icons/io5";

function Advertiser() {
  const { isAdvertiser } = useContext(NavContext);
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  const [isCancelopen, setCancelopen] = useState(true);
  const { isOpen: isOpenn, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    // Add your Google Tag Manager script here
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WLXVVHB4";
    script.async = true;
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Hero2 />
      <Properties2 />
      <Explainer />
      <Insights />
      <Integrations />
      <Penetrate />
      <Comments />
      <Faq />
      <Still />
    </>
  );
}

export default Advertiser;
