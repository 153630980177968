import React, { useEffect } from "react";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
const PrivacyPolicy = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Flex direction={"column"}>
      <Flex
        justify={"center"}
        background={
          isDesktop
            ? "linear-gradient(90deg, #fff +2.38%, #c49bee 98.36%)"
            : "#f8f4fd"
        }
      >
        <Flex
          align="center"
          justify={"center"}
          minH="25vh"
          direction={"column"}
        >
          <Heading
            as="h1"
            size="xl"
            fontWeight="700"
            color="primary.800"
            textAlign={["center", "center", "left", "left"]}
          >
            Privacy Policy
          </Heading>
          <Box w={"70%"}>
            <Text textAlign={"center"}>
              Please read these Privacy Policy carefully as they contain
              important information for the use of metricks.io
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex direction={"column"} my={20} width={"100%"} align={"center"}>
        <Flex
          direction={"column"}
          borderColor={"black"}
          borderWidth={1}
          width={"80%"}
          // align={"center"}
          p={10}
        >
          <Text>
            At metricks, Accessible From Http://metricks.io, One Of Our Main
            Priorities Is The Privacy Of Our Users. This Privacy Policy Document
            Contains Types Of Information That Is Collected And Recorded By
            metricks.io And How We Use It.
          </Text>
          <Text mt={5}>
            This Privacy Policy Applies Only To Our Online Activities And Is
            Valid For Visitors To Our Software With Regards To The Information
            That They Shared And/Or Collected In metricks.io. This Policy Is Not
            Applicable To Any Information Collected Offline Or Via Channels
            Other Than This Software.
          </Text>
          <Text mt={5}>
            We Only Disclose Your Personal Data To Third Parties That Assist Us
            With Providing You With Our Services And, If You Authorize Us
            Explicitly, To Our Affiliated Companies For The Purpose Of Customer
            Relationship Management, Analytics And Marketing.
          </Text>

          <Heading mt={10}>Consent</Heading>
          <Text mt={10}>
            By Using Our Software, You Hereby Consent To Our Privacy Policy And
            Agree To Its Terms.
          </Text>
          <Heading mt={10}>Information we collect</Heading>
          <Text mt={10}>
            The Personal Information That You Are Asked To Provide, And The
            Reasons Why You Are Asked To Provide It, Will Be Made Clear To You
            At The Point We Ask You To Provide Your Personal Information.
          </Text>
          <Text mt={5}>
            Unless Specified Otherwise, All Data Requested By metricks Is
            Mandatory And Failure To Provide This Data May Make It Impossible
            For metricks To Provide Its Services. In Cases Where metricks
            Specifically States That Some Data Is Not Mandatory, Users Are Free
            Not To Communicate This Data Without Consequences To The
            Availability Or The Functioning Of The Service...
          </Text>
          <Text mt={5}>
            Users Are Responsible For Any Third-Party Personal Data Obtained,
            Published Or Shared Through This Software And Confirm That They Have
            The Third Party's Consent To Provide The Data To The Owner.
          </Text>
          <Heading mt={10}>Log data</Heading>
          <Text mt={10}>
            metricks.io Follows A Standard Procedure Of Using Log Files. These
            Files Log Visitors When They Visit Websites. All Hosting Companies
            Do This As Part Of Hosting Services' Analytics. The Information
            Collected By Log Files Include Internet Protocol (IP) Addresses,
            Browser Type, Internet Service Provider (ISP), Date And Time Stamp,
            Referring/Exit Pages, And Possibly The Number Of Clicks. These Are
            Not Linked To Any Information That Is Personally Identifiable. The
            Purpose Of The Information Is For Analyzing Trends, Administering
            The Site, Tracking Users' Movement On The Website, And Gathering
            Demographic Information.
          </Text>
          <Heading mt={10}>Cookies and Web Beacons</Heading>
          <Text mt={10}>
            Like Any Other Website, metricks.io Uses 'Cookies'. These Cookies
            Are Used To Store Information Including Visitors' Preferences, And
            The Pages On The Website That The Visitor Accessed Or Visited. The
            Information Is Used To Optimize The Users' Experience By Customizing
            Our Web Page Content Based On Visitors' Browser Type And/Or Other
            Information.
          </Text>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Provide, Operate, And Maintain Our Software</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Improve, Personalize, And Expand Our Software</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Understand And Analyze How You Use Our Software</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              Develop New Products, Services, Features, And Functionality
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              Communicate With You, Either Directly Or Through One Of Our
              Partners, Including For Customer Service, To Provide You With
              Updates And Other Information Relating To The Software, And For
              Marketing And Promotional Purposes
            </Text>
          </Flex>
          <Text mt={10} fontSize={24}>
            Personal information
          </Text>
          <Text mt={5}>We May Ask For Personal Information, Such As Your:</Text>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Name</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Email</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Phone/Mobile Number</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Home/Mailing Address</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Payment Information</Text>
          </Flex>

          <Heading mt={10}>Third-Party Privacy Policies</Heading>
          <Text mt={10}>
            metricks.io's Privacy Policy Does Not Apply To Other Advertisers Or
            Websites. Thus, We Are Advising You To Consult The Respective
            Privacy Policies Of These Third-Party Ad Servers For More Detailed
            Information. It May Include Their Practices And Instructions About
            How To Opt Out Of Certain Options.
          </Text>
          <Text mt={5}>
            You Can Choose To Disable Cookies Through Your Individual Browser
            Options. To Know More Detailed Information About Cookie Management
            With Specific Web Browsers, It Can Be Found At The Browsers'
            Respective Websites.
          </Text>
          <Heading mt={10}>Legal bases for processing</Heading>
          <Text mt={10}>
            We Will Process Your Personal Information Lawfully, Fairly And In A
            Transparent Manner. We Collect And Process Information About You
            Only Where We Have Legal Bases For Doing So. These Legal Bases
            Depend On The Services You Use And How You Use Them, Meaning We
            Collect And Use Your Information Only Where:
          </Text>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              It’s Necessary For The Performance Of A Contract To Which You Are
              A Party Or To Take Steps At Your Request Before Entering Into Such
              A Contract (For Example, When We Provide A Service You Request
              From Us);
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              It Satisfies A Legitimate Interest (Which Is Not Overridden By
              Your Data Protection Interests), Such As For Research And
              Development, To Market And Promote Our Services, And To Protect
              Our Legal Rights And Interests;
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              You Give Us Consent To Do So For A Specific Purpose (For Example,
              You Might Consent To Us Sending You Our Newsletter); Or
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              We Need To Process Your Data To Comply With A Legal Obligation.
            </Text>
          </Flex>

          <Text mt={5}>
            We Don’t Keep Personal Information For Longer Than Is Necessary.
            While We Retain This Information, We Will Protect It Within
            Commercially Acceptable Means To Prevent Loss And Theft, As Well As
            Unauthorised Access, Disclosure, Copying, Use Or Modification. That
            Said, We Advise That No Method Of Electronic Transmission Or Storage
            Is 100% Secure And Cannot Guarantee Absolute Data Security. If
            Necessary, We May Retain Your Personal Information For Our
            Compliance With A Legal Obligation Or In Order To Protect Your Vital
            Interests Or The Vital Interests Of Another Natural Person.
          </Text>
          <Heading mt={10}>Collection and use of information</Heading>
          <Text mt={10}>
            We May Collect, Hold, Use And Disclose Information For The Following
            Purposes And Personal Information Will Not Be Further Processed In A
            Manner That Is Incompatible With These Purposes:
          </Text>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              To Enable You To Customise Or Personalise Your Experience Of Our
              Software;
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              To Enable You To Access And Use Our Software, Associated
              Applications And Associated Social Media Platforms;
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>To Contact And Communicate With You;</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              For Internal Record Keeping And Administrative Purposes;
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              For Analytics, Market Research And Business Development, Including
              To Operate And Improve Our Software, Associated Applications And
              Associated Social Media Platforms;
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              To Run Competitions And/Or Offer Additional Benefits To You
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              For Advertising And Marketing, Including Sending You Promotional
              Information About Our Products And Services And Information About
              Third Parties That We Consider May Be Of Interest To You; And
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              To Comply With Our Legal Obligations And Resolve Any Disputes That
              We May Have.
            </Text>
          </Flex>

          <Heading mt={10}>
            Disclosure of personal information to third parties
          </Heading>
          <Text mt={10}>We May Disclose Personal Information To:</Text>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              Third Party Service Providers For The Purpose Of Enabling Them To
              Provide Their Services, Including (Without Limitation) IT Service
              Providers, Data Storage, Hosting And Server Providers, Ad
              Networks, Analytics, Error Loggers, Debt Collectors, Maintenance
              Or Problem-Solving Providers, Marketing Or Advertising Providers,
              Professional Advisors And Payment Systems Operators;
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>Our Employees, Contractors And/Or Related Entities; And</Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              Credit Reporting Agencies, Courts, Tribunals And Regulatory
              Authorities, In The Event You Fail To Pay For Goods Or Services We
              Have Provided To You.
            </Text>
          </Flex>
          <Heading mt={10}>
            International transfers of personal information
          </Heading>

          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              The Personal Information We Collect Is Stored And Processed In The
              United States, Or Where We Or Our Partners, Affiliates And
              Third-Party Providers Maintain Facilities. By Providing Us With
              Your Personal Information, You Consent To The Disclosure To These
              Overseas Third Parties.
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              We Will Ensure That Any Transfer Of Personal Information From
              Countries In The European Economic Area (EEA) To Countries Outside
              The EEA Will Be Protected By Appropriate Safeguards, For Example
              By Using Standard Data Protection Clauses Approved By The European
              Commission, Or The Use Of Binding Corporate Rules Or Other Legally
              Accepted Means.
            </Text>
          </Flex>
          <Flex gap={3} mt={5} align={"center"}>
            <Circle backgroundColor={"#000"} size={3}></Circle>
            <Text>
              Where We Transfer Personal Information From A Non-EEA Country To
              Another Country, You Acknowledge That Third Parties In Other
              Jurisdictions May Not Be Subject To Similar Data Protection Laws
              To The Ones In Our Jurisdiction. There Are Risks If Any Such Third
              Party Engages In Any Act Or Practise That Would Contravene The
              Data Privacy Laws In Our Jurisdiction And This Might Mean That You
              Will Not Be Able To Seek Redress Under Our Jurisdiction’s Privacy
              Laws.
            </Text>
          </Flex>
          <Heading mt={10}>
            Your rights and controlling your personal information
          </Heading>
          <Text mt={10}>
            Choice And Consent: By Providing Personal Information To Us, You
            Consent To Us Collecting, Holding, Using And Disclosing Your
            Personal Information In Accordance With This Privacy Policy. If You
            Are Under 16 Years Of Age, You Must Have And Warrant To The Extent
            Permitted By Law To Us, That You Have Your Parent Or Legal
            Guardian’s Permission To Access And Use The Software And They (Your
            Parents Or Guardian) Have Consented To You Providing Us With Your
            Personal Information. You Do Not Have To Provide Personal
            Information To Us, However, If You Do Not, It May Affect Your Use Of
            This Software Or The Products And/Or Services Offered On Or Through
            It.
          </Text>
          <Heading mt={10}>Information from third parties</Heading>
          <Text mt={10}>
            If We Receive Personal Information About You From A Third Party, We
            Will Protect It As Set Out In This Privacy Policy. If You Are A
            Third Party Providing Personal Information About Somebody Else, You
            Represent And Warrant That You Have Such Person’s Consent To Provide
            The Personal Information To Us.
          </Text>
          <Heading mt={10}>Restrict</Heading>
          <Text mt={10}>
            You May Choose To Restrict The Collection Or Use Of Your Personal
            Information. If You Have Previously Agreed To Us Using Your Personal
            Information For Direct Marketing Purposes, You May Change Your Mind
            At Any Time By Contacting Us Using The Details Below. If You Ask Us
            To Restrict Or Limit How We Process Your Personal Information, We
            Will Let You Know How The Restriction Affects Your Use Of Our
            Software Or Products And Services.
          </Text>
          <Heading mt={10}>Access data and portability</Heading>
          <Text mt={10}>
            You May Request Details Of The Personal Information That We Hold
            About You. You May Request A Copy Of The Personal Information We
            Hold About You. Where Possible, We Will Provide This Information In
            CSV Format Or Another Easily Readable Machine Format. You May
            Request That We Erase The Personal Information We Hold About You At
            Any Time. You May Also Request That We Transfer This Personal
            Information To Another Third Party.
          </Text>
          <Heading mt={10}>Correction</Heading>
          <Text mt={10}>
            If You Believe That Any Information We Hold About You Is Inaccurate,
            Out Of Date, Incomplete, Irrelevant Or Misleading, Please Contact Us
            Using The Details Below. We Will Take Reasonable Steps To Correct
            Any Information Found To Be Inaccurate, Incomplete, Misleading Or
            Out Of Date.
          </Text>
          <Heading mt={10}>Notification of data breaches</Heading>
          <Text mt={10}>
            We Will Comply With Laws Applicable To Us In Respect Of Any Data
            Breach.
          </Text>
          <Heading mt={10}>Complaint</Heading>
          <Text mt={10}>
            If You Believe That We Have Breached A Relevant Data Protection Law
            And Wish To Make A Complaint, Please Contact Us Using The Details
            Below And Provide Us With Full Details Of The Alleged Breach. We
            Will Promptly Investigate Your Complaint And Respond To You, In
            Writing, Setting Out The Outcome Of Our Investigation And The Steps
            We Will Take To Deal With Your Complaint. You Also Have The Right To
            Contact A Regulatory Body Or Data Protection Authority In Relation
            To Your Complaint.
          </Text>
          <Heading mt={10}>Unsubscribe</Heading>
          <Text mt={10}>
            To Unsubscribe From Our Email Database Or Opt-Out Of Communications
            (Including Marketing Communications), Please Contact Us Using The
            Details Below Or Opt-Out Using The Opt-Out Facilities Provided In
            The Communication.
          </Text>
          <Heading mt={10}>Cookies</Heading>
          <Text mt={10}>
            We Use “Cookies” To Collect Information About You And Your Activity
            Across Our Site. A Cookie Is A Small Piece Of Data That Our Software
            Stores On Your Computer, And Accesses Each Time You Visit, So We Can
            Understand How You Use Our Site. This Helps Us Serve You Content
            Based On The Preferences You Have Specified.
          </Text>
          <Heading mt={10}>Business transfer</Heading>
          <Text mt={10}>
            If We Or Our Assets Are Acquired, Or In The Unlikely Event That We
            Go Out Of Business Or Enter Bankruptcy, We Would Include Data Among
            The Assets Transferred To Any Parties Who Acquire Us. You
            Acknowledge That Such Transfers May Occur And That Any Parties Who
            Acquire Us May Continue To Use Your Personal Information According
            To This Policy.
          </Text>
          <Heading mt={10}>Limits of our policy</Heading>
          <Text mt={10}>
            Our Software May Link To External Sites That Are Not Operated By Us.
            Please Be Aware That We Have No Control Over The Content And
            Policies Of Those Sites, And Cannot Accept Responsibility Or
            Liability For Their Respective Privacy Practices.
          </Text>
          <Heading mt={10}>Changes to our policy</Heading>
          <Text mt={10}>
            At Our Discretion, We May Change Our Privacy Policy To Reflect
            Current Acceptable Practices. We Will Take Reasonable Steps To Let
            Users Know About Changes Via Our Software. Your Continued Use Of
            This Site After Any Changes To This Policy Will Be Regarded As
            Acceptance Of Our Practices Around Privacy And Personal Information.
          </Text>
          <Text mt={10}>
            If We Make A Significant Change To This Privacy Policy, For Example
            Changing A Lawful Basis On Which We Process Your Personal
            Information, We Will Ask You To Re-Consent To The Amended Privacy
            Policy.
          </Text>
          <Heading mt={10}>Contact Us</Heading>
          <Text mt={10}>
            If You Have Any Questions About These Terms, Please Contact Us Over
            Live Chat Or Email Support@metricks.io
          </Text>
        </Flex>
      </Flex>
      {/* dsdsd */}
      <Flex
        style={{
          background:
            "conic-gradient(from 5.87deg at 58.99% 0%, rgba(114, 17, 212, 0) -118.12deg, rgba(114, 17, 212, 0.56) 20.77deg, rgba(114, 17, 212, 0) 241.88deg, rgba(114, 17, 212, 0.56) 380.77deg)",
        }}
        justify={"center"}
      >
        <Flex
          w={"80%"}
          align={"center"}
          justify={{ md: "center", base: "center" }}
        >
          <Flex
            align="center"
            // direction={{ base: "column-reverse", md: "row" }}
            w={{ base: "100%", md: "50%" }}
            py={20}
          >
            <Flex
              //   divider={<StackDivider borderColor='gray.200' />}
              gap={10}
              align={{ base: "center", md: "center" }}
              // justify={{ md: "flex-start", base: "center" }}
              // backgroundColor={"red"}
              direction={"column"}
            >
              <Heading textAlign={{ base: "center", md: "center" }}>
                Take your affiliate marketing to the next level.
              </Heading>

              <Flex alignItems={"flex-start"} gap={5}>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#000"
                    backgroundColor="#fff"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Sign up
                  </Button>
                </Link>
                <Link to="https://app.metricks.io/signup">
                  <Button
                    color="#fff"
                    backgroundColor="#7211D4"
                    shadow={"2xl"}
                    borderRadius={50}
                    py="7"
                    px="6"
                    lineHeight="1"
                    size="md"
                    rightIcon={<ChevronRightIcon />}
                    // href={"https://metricks-main-app-v2.vercel.app/login"}
                  >
                    Talk to a Human
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PrivacyPolicy;
