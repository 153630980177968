// import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Advertiser from "./pages/Advertiser";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import TermsandCondition from "./pages/TermsandCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AppsIntegration from "./pages/AppsIntegration";
import Pricing from "./pages/Pricing";
// import AffPretaSHop from "./pages/Affiliate/AffPrestaShop";
import AffPrestaShop from "./pages/Affiliate/AffPrestaShop";
import AffEcwid from "./pages/Affiliate/AffEcwid";
import AffLightspeed from "./pages/Affiliate/AffLightspeed";
import AffWoocommerce from "./pages/Affiliate/AffWoocommerce";
import AffWordPress from "./pages/Affiliate/AffWordPress";
import AffWix from "./pages/Affiliate/AffWix";
import AffPaddle from "./pages/Affiliate/AffPaddle";
import AffPaypal from "./pages/Affiliate/AffPaypal";
import AffSSpace from "./pages/Affiliate/AffSSpace";
import AffStripe from "./pages/Affiliate/AffStripe";
import AffCratejoy from "./pages/Affiliate/AffCratejoy";
import AffMoonclerk from "./pages/Affiliate/AffMoonclerk";
import AffMemberful from "./pages/Affiliate/AffMemberful";
import Navbar2 from "./components/Navbar/Navbar2";
import Ecommerce from "./pages/Ecommerce";

import ForAffiliate from "./pages/ForAffiliate";
import ForSaas from "./pages/ForSaas";
import AffShopify from "./pages/Affiliate/AffShopify";

export default function App() {
  return (
    <>
      <Navbar2 />
      <Routes>
        <Route path="/" element={<Advertiser />} />
        <Route path="/forAffiliate" element={<ForAffiliate />} />
        <Route path="/termsandconditions" element={<TermsandCondition />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Appsintegration" element={<AppsIntegration />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Prestashop" element={<AffPrestaShop />} />
        <Route path="/Ecwid" element={<AffEcwid />} />
        <Route path="/Lightspeed" element={<AffLightspeed />} />
        <Route path="/woocommerce" element={<AffWoocommerce />} />
        <Route path="/shopify" element={<AffShopify />} />
        <Route path="/wordpress" element={<AffWordPress />} />
        <Route path="/wix" element={<AffWix />} />
        <Route path="/paddle" element={<AffPaddle />} />
        <Route path="/paypal" element={<AffPaypal />} />
        <Route path="/squarespace" element={<AffSSpace />} />
        <Route path="/stripe" element={<AffStripe />} />
        <Route path="/cratejoy" element={<AffCratejoy />} />
        <Route path="/moonclerk" element={<AffMoonclerk />} />
        <Route path="/memberful" element={<AffMemberful />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/forsaas" element={<ForSaas />} />
      </Routes>
      <Footer />
    </>
  );
}
