import {
  Avatar,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { LiaBullseyeSolid } from "react-icons/lia";
import { Swiper, SwiperSlide } from "swiper/react";
import { GiStarShuriken } from "react-icons/gi";
import { GoTriangleRight } from "react-icons/go";
import { HiArrowUp } from "react-icons/hi";
import { Link } from "react-router-dom";
const Still = () => {
  const [isDesktop] = useMediaQuery("(min-width: 1050px)");
  return (
    <Flex
      justify={"center"}
      bgColor={isDesktop ? "#fff" : "#2C2D2D"}
      w={"100%"}
      py={3}
    >
      <Flex
        align="center"
        justify={"center"}
        w={"95%"}
        py={10}
        flexDir={"column"}
        gap={5}
        position={"relative"}
        borderRadius={50}
        overflow={"hidden"}
        bgColor={"#2C2D2D"}
        paddingX={"16px"}
      >
        {" "}
        <Flex
          align={"center"}
          gap={3}
          px={3}
          py={1}
          borderWidth={1}
          borderColor={"#39463A"}
          shadow={"base"}
          boxShadow={"0 0 7px rgba(0, 128, 0, 0.5)"}
          borderRadius={50}
        >
          {/* <GiStarShuriken fontSize={20} /> */}
          <HiArrowUp color="rgba(0, 128, 0)" />
          <Text fontSize={15} fontWeight={"600"} color={"rgba(0, 128, 0)"}>
            3.24%
          </Text>
        </Flex>
        <Text
          fontWeight={"500"}
          fontSize={{ base: "1.5rem", md: "1.875rem" }}
          lineHeight={{ base: "32px", md: "40px" }}
          color={"white"}
          mt={5}
          textAlign={"center"}
          w={"90%"}
        >
          Still got questions? See Metricks in Action
        </Text>
        <Text
          fontWeight={"300"}
          fontSize={13}
          textAlign={"center"}
          w={["90%", "30%"]}
          color={"#9394A1"}
        >
          Launch Your Affiliate Program in 5 minutes, Track, Optimize, and
          Measure the Performance of your Affiliate Programs with Ease.
        </Text>
        <Flex
          gap={5}
          flexDir={isDesktop ? "row" : "column"}
          w={isDesktop ? "" : "80%"}
          mt={5}
        >
          <Link to="https://cal.com/metricks/15min">
            <Button
              rightIcon={<GoTriangleRight />}
              color={"#fff"}
              borderRadius={10}
              fontWeight={"500"}
              // boxShadow="custom"
              _hover={{
                color: "#fff",
                //   bgColor: "#c4c4c4",
              }}
              bgGradient="linear(#8C3DDC 40%, #7415D5 60%)"
              w={isDesktop ? "" : "100%"}
            >
              {"  "}Book a Demo{"  "}
            </Button>
          </Link>
          {/* <Link to="https://app.metricks.io/signup">
            <Button
              rightIcon={<GoTriangleRight />}
              color={"#000"}
              borderRadius={10}
              fontWeight={"500"}
              shadow={"base"}
              w={isDesktop ? "" : "100%"}
              cursor={"pointer"}
              _hover={{
                color: "#c4c4c4",
                //   bgColor: "#c4c4c4",
              }}
              bgColor={"#fff"}
              zIndex={1}
              fontSize={"16px"}
            >
              Launch Your Growth Engine Now
            </Button>
          </Link> */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Still;
